<template>
  <div class="body">
    <v-snackbar
      v-model="snackbar.status"
      :color="snackbar.color"
      :timeout="timeout"
       location="top"
      >{{ snackbar.msg }}</v-snackbar
    >
    <v-row>
      <v-col cols="12" lg="5" class="bg-dark body">
        <v-container class="w-90 mx-auto">
          <h1 class="mt-5 ms-3">
            <v-img
              src="../../assets/cpLogo.png"
              width="80"
              height="100"
            ></v-img>
          </h1>
          <h1 class="marginTop-10 header-2 ms-3">Sign In</h1>

          <v-form @submit.prevent="login" ref="formRef">
            <v-container>
              <v-row>
                <v-col cols="12">
                  <span class="mb-2 font-weight-bold">Email Address</span>
                  <v-text-field
                    placeholder="johndoe@gmail.com"
                    v-model="loginForm.email"
                    variant="solo"
                    type="email"
                    class="mt-2"
                    append-inner-icon="mdi-email-outline"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <span class="mb-2 font-weight-bold">Password</span>
                  <v-text-field
                    placeholder="********"
                    v-model="loginForm.password"
                    variant="solo"
                    class="mt-2 p-3"
                    :append-inner-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show1 ? 'text' : 'password'"
                    @click:append-inner="show1 = !show1"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-btn
                    class="bg-primary p-5"
                    block
                    type="submit"
                    :disabled="loading"
                    :loading="loading"
                    >Login</v-btn
                  >
                  <p class="text-primary text-center mt-4">
                    <router-link
                      to="#"
                      class="text-primary text-decoration-none"
                      >Forgot Password ?</router-link
                    >
                  </p>
                </v-col>

                <v-col cols="12" class="mt-10 pb-10">
                  <p class="text-darker text-center">
                    Send a mail to
                    <router-link to="#" class="text-dark-2 text-decoration-none"
                      >admin@champsplay.com</router-link
                    >
                    to request access
                  </p>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-container>
      </v-col>
      <v-col cols="12" sm="7" class="bg-primary d-none d-lg-block">
        <div class="pt-7 pb-0 ms-5">
          <p class="header ms-18 font-weight-bolder">
            Champs Play<br class="d-none d-lg-block" />
            Admin Dashboard
          </p>
          <p class="text-white ms-18">
            Stay up to date will all that is happening on the mobile
            application.
          </p>
          <div class="mb-0">
            <v-img
              src="../../assets/champsPlayerMockUp.svg"
              alt="Chamsplay Mockup"
              class="position-relative"
              style="top: 0.98rem"
            >
              <template v-slot:placeholder>
                <div class="d-flex align-center justify-center fill-height">
                  <v-progress-circular
                    color="grey-lighten-4"
                    indeterminate
                  ></v-progress-circular>
                </div>
              </template>
            </v-img>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script setup>
import { ref, reactive } from "vue"

import { useRouter, useRoute } from "vue-router"
import { useStore } from "vuex"
import axiosInstance from "@/plugins/axios.js"

const router = useRouter()
const store = useStore()

const formRef = ref(null)

const show1 = ref(false)

const loading = ref(false)

const timeout = ref(8000)

const snackbar = reactive({
  status: false,
  msg: "",
  color: "",
})

// form submission
const loginForm = reactive({
  email: "",
  password: "",
})

async function login() {
  loading.value = true

  axiosInstance
    .post(`/admin/auth/login`, loginForm)
    .then((response) => {

      // console.log(response)

      if(response.data.status == false){

        snackbar.status = true
        snackbar.color = "yellow"
        snackbar.msg = response.data.message
        loading.value = true
      } else {

        let Response = response.data
        let userObject = {
          token: Response.token,
        }
        axiosInstance.defaults.headers.Authorization = `Bearer ${userObject.token}`
  
        localStorage.setItem("token", userObject.token)
  
        store.commit("setUser", userObject)
  
        formRef.value.reset()
  
        router.push({ name: "Dashboard" })
      }
        loading.value = false
      
      // console.log(response)


    })
    .catch((error) => {
      // console.log(error)
      loading.value = false

      snackbar.status = true
      snackbar.msg = error.response.data.message
     
      snackbar.color = "red"
    })
}
</script>
<style scoped>
.body {
  box-sizing: border-box;
  min-height: 100vh;
}

.header {
  font-size: 3.2rem;
  font-weight: 800;
}

.header-2 {
  font-size: 2.5rem;
}
.marginTop-10 {
  margin-top: 7rem;
}

.ms-18 {
  margin-left: 6rem;
}

.position-relative {
  position: relative;
}
.position-absolute {
  position: absolute;
}

* {
  font-family: "Nunito", sans-serif;
  /* outline: 1px solid green; */
  min-height: 70%;
}

.w-90 {
  width: 90%;
}

@media screen and (max-width: 600px) {
  .body {
    box-sizing: border-box;
    min-height: 50vh;
  }
}
</style>
