<template>
  <AdminLayout page="Profile">
    <v-container v-if="overlay">
       <v-row class="d-flex justify-center">
        <v-col cols="6">

            <v-skeleton-loader type="card" max-width="600"></v-skeleton-loader>
        </v-col>
       </v-row>
    </v-container>
    <v-container v-else>
      <v-card class="mx-auto elevation-4 pa-3" max-width="644">
        <v-card-item>
          <v-card-title class="d-flex justify-center">
            <v-img
              src="../../assets/bolajiAmusan.svg"
              width="200"
              height="200"
            ></v-img
          ></v-card-title>
          <h2 class=" mt-5 fs-4 text-center font-weight-light">Name: <span class="fs-4 font-weight-thin">{{ userDetails.fullname }}</span>  </h2>
          <h3 class="fs-4 mt-5 text-center font-weight-light">Email: <span class="fs-4 font-weight-thin">{{ userDetails.email }}</span>  </h3>
          <h3 class="fs-4 mt-5 text-center font-weight-light">Phone Number: <span class="fs-4 font-weight-thin">{{ userDetails.phone }}</span>  </h3>
        </v-card-item>

       
      </v-card>
    </v-container>
  </AdminLayout>
</template>
<script setup>
import AdminLayout from "../../components/AdminLayout.vue"
import axiosInstance from "../../plugins/axios"

import { ref, reactive, onMounted } from "vue"

const overlay = ref(true)
const userDetails = ref([])

async function getUserProfile() {
 try{
  const profile = await axiosInstance.get(`/admin/profile`)

  userDetails.value = profile.data.data
  console.log(userDetails.value)
  overlay.value = false;
 }catch(error){
   console.log(error);
 }
}

onMounted(() => {
  getUserProfile()
})
</script>
<style scoped>
.fs-4{
    font-size:1.7rem;
}
</style>
