<template>
  <AdminLayout page="Blog">
    <v-snackbar
      v-model="snackbar.status"
      :color="snackbar.color"
      :timeout="snackbar.timeout"
      location="top"
      >{{ snackbar.msg }}</v-snackbar
    >
    <v-container v-if="overlay">
      <v-row>
        <v-col cols="4">
          <v-skeleton-loader type="card"></v-skeleton-loader>
        </v-col>
        <v-col cols="4">
          <v-skeleton-loader type="card"></v-skeleton-loader>
        </v-col>
        <v-col cols="4">
          <v-skeleton-loader type="card"></v-skeleton-loader>
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid v-else>
      <v-row>
        <v-col cols="12" md="8" lg="9">
          <p class="mt-0 text-dark-2">
            Manage and Keep track of blog and media materials
          </p>
        </v-col>
      </v-row>

      <v-row class="mt-5 bg-darker-2">
        <v-col cols="12" class="d-flex justify-center">
          <div class="d-flex bg-dark-5 rounded px-5 py-3 align-center">
            <div>
              <button
                class="queryBtn"
                :class="{ active: blogs }"
                @click.prevent="showBlogs"
              >
                Blog Materials
              </button>
            </div>
            <div>
              <button
                class="queryBtn"
                @click="showAds"
                :class="{ active: Ads }"
              >
                Media for ADS
              </button>
            </div>
            <div>
              <button class="queryBtn d-flex align-center">
                <v-img
                  src="../../assets/calendar.svg"
                  width="30"
                  height="20"
                  class="me-1"
                ></v-img
                >Select Period
              </button>
            </div>
          </div>
        </v-col>
      </v-row>

      <v-row class="mt-6" v-if="blogs">
        <v-col
          cols="12"
          lg="6"
          class="d-flex align-stretch flex-column align-center justify-center"
        >
          <v-card
            class="d-flex flex-column justify-center align-center elevation-0 pa-4 bg-dark"
          >
            <v-img
              src="../../assets/addsquare.svg"
              width="60"
              height="60"
              @click="dialog = true"
            ></v-img>
            <!-- <p class="font-weight-light mt-3"></p> -->

            <v-dialog v-model="dialog" width="800" opacity="-0.5">
              <template v-slot:activator="{ props }">
                <v-btn color="primary" v-bind="props" variant="text">
                  Upload New Materials
                </v-btn>
              </template>
              <!-- <v-card> -->
              <v-card class="pa-6 bg-dark elevation-3">
                <v-card-text>
                  <div class="d-flex align-center justify-space-between">
                    <div>
                      <h3 class="fs-6">Upload Blog Material</h3>
                      <!-- <p></p> -->
                    </div>
                    <div>
                      <!-- <v-img src="../../assets/close-btn.png" width="40" height="40"></v-img> -->
                      <v-btn icon="mdi-close" @click="dialog = false"></v-btn>
                    </div>
                  </div>
                  <v-form class="mt-5" @submit.prevent="uploadBlogs">
                    <v-row>
                      <v-col cols="12">
                        <p class="mb-3">Header Image</p>
                        <!-- <v-file-input
                            label="File input"
                            variant="solo"
                            size="small"
                            v-model="addBlogs.image"
                          ></v-file-input> -->
                        <input type="file" />
                      </v-col>
                      <v-col cols="12">
                        <p class="mb-3">Blog Title</p>
                        <v-text-field
                          variant="solo"
                          v-model="addBlogs.name"
                          placeholder="Enter Blog Title"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12">
                        <p class="mb-3">Description</p>
                        <v-textarea
                          variant="solo"
                          v-model="addBlogs.description"
                          placeholder="Enter your description"
                        ></v-textarea>
                      </v-col>

                      <v-col cols="12">
                        <p class="mb-3">Status</p>
                        <v-switch
                          v-model="addBlogs.is_active"
                          hide-details
                          hint="Enter a true or false Statement"
                          :label="`Switch: ${addBlogs.is_active.toString()}`"
                        ></v-switch>
                      </v-col>
                      <v-col cols="12">
                        <v-btn type="submit" color="primary" block size="large"
                          >Upload</v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-card>
        </v-col>
        <v-col
          cols="12"
          lg="6"
          class=""
          v-for="blog in blogDetails"
          :key="blog.id"
        >
          <v-card class="bg-dark-5">
            <v-row>
              <v-col cols="6" class="align-stretch">
                <v-img
                  src="../../assets/blogg.svg"
                  width="250"
                  height="280"
                  cover
                >
                  <template v-slot:placeholder>
                    <div class="d-flex align-center justify-center fill-height">
                      <v-progress-circular
                        color="grey-lighten-4"
                        indeterminate
                      ></v-progress-circular>
                    </div>
                  </template>
                </v-img>
              </v-col>
              <v-col cols="6">
                <div class="pa-2">
                  <p class="fs-6 text-capitalize">{{ blog.name }}</p>
                  <div class="mt-3">
                    <v-dialog v-model="dialog3" width="800" opacity="-0.5">
                      <template v-slot:activator="{ props }">
                        <v-btn
                          color="primary"
                          v-bind="props"
                          size="small"
                          class="ma-2"
                          variant="tonal"
                          @click="getSingleBlogDetail(blog.id)"
                        >
                          View
                        </v-btn>
                      </template>
                      <!-- <v-card> -->
                      <v-card class="pa-6 bg-dark elevation-3">
                        <p class="text-end">
                          <!-- <v-img src="../../assets/close-btn.png" width="40" height="40"></v-img> -->
                          <v-btn
                            icon="mdi-close"
                            @click="dialog3 = false"
                          ></v-btn>
                        </p>
                        <v-card-text>
                          <p class="text-capitalize fs-4">
                            <span class="text-primary">More About:</span>
                            {{ singleBlogDetails.name }}
                          </p>
                          <v-row>
                            <v-col cols="12">
                              <v-img src="../../assets/blogg.svg" cover></v-img>
                            </v-col>
                            <v-col cols="12">
                              <p
                                class="fs-4 text-capitalize font-weight-bolder"
                              >
                                {{ singleBlogDetails.name }}
                              </p>
                              <p
                                class="fs-6 text-capitalize font-weight-medium mt-6"
                              >
                                {{ singleBlogDetails.description }}
                              </p>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-dialog>
                    <v-dialog v-model="dialog4" width="800" opacity="-0.5">
                      <template v-slot:activator="{ props }">
                        <v-btn
                          v-bind="props"
                          size="small"
                          class="ma-1"
                          variant="outlined"
                          @click="getSingleBlogDetail(blog.id)"
                        >
                          Edit
                        </v-btn>
                      </template>
                      <!-- <v-card> -->
                      <v-card class="pa-6 bg-dark elevation-3">
                        <p class="text-end">
                          <!-- <v-img src="../../assets/close-btn.png" width="40" height="40"></v-img> -->
                          <v-btn
                            icon="mdi-close"
                            @click="dialog4 = false"
                          ></v-btn>
                        </p>
                        <v-card-text>
                          <v-form>
                            <v-row>
                              <v-col cols="12">
                                <p class="mb-3">Blog Title</p>
                                <v-text-field
                                  variant="solo"
                                  placeholder="Enter Blog Title"
                                  v-model="singleBlogDetails.name"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12">
                                <p class="mb-3">Blog Description</p>

                                <v-text-field
                                  variant="solo"
                                  placeholder="Enter Description"
                                  v-model="singleBlogDetails.description"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12">
                                <p class="mb-3">Header Image</p>
                                <v-file-input
                                  label="File input"
                                  variant="solo"
                                  v-model="singleBlogDetails.image"
                                ></v-file-input>
                              </v-col>
                              <v-col cols="12">
                                <p class="mb-3">Status</p>
                                <v-switch
                                  v-model="singleBlogDetails.is_active"
                                  hide-details
                                  :model-value="
                                    singleBlogDetails.is_active == 1
                                      ? true
                                      : false
                                  "
                                ></v-switch>
                              </v-col>
                              <v-col cols="12">
                                <v-btn
                                  type="submit"
                                  color="primary"
                                  block
                                  size="large"
                                  @click.prevent="
                                    updateSingleBlogDetail(blog.id)
                                  "
                                  >Update</v-btn
                                >
                              </v-col>
                            </v-row>
                          </v-form>
                        </v-card-text>
                      </v-card>
                    </v-dialog>

                    <v-btn
                      variant="text"
                      size="small"
                      class="ma-1"
                      color="red"
                      @click.prevent="deleteBlog(blog.id)"
                      >Delete</v-btn
                    >
                  </div>
                  <p class="text-dark-2 mt-4">Uploaded by Muburak Ogunlabi</p>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-col>

        <v-col cols="12">
          <div class="mt-5">
            <v-pagination :length="4"></v-pagination>
          </div>
        </v-col>
      </v-row>

      <v-row class="mt-6" v-if="Ads">
        <v-col
          cols="12"
          lg="6"
          class="d-flex align-stretch flex-column align-center justify-center"
        >
          <v-card
            class="d-flex flex-column justify-center align-center elevation-0 pa-4 bg-dark"
          >
            <v-img
              src="../../assets/addsquare.svg"
              width="60"
              height="60"
              @click="dialog2 = true"
            ></v-img>
            <!-- <p class="font-weight-light mt-3"></p> -->

            <v-dialog v-model="dialog2" width="800">
              <template v-slot:activator="{ props }">
                <v-btn color="primary" v-bind="props" variant="text">
                  Upload new Ads
                </v-btn>
              </template>
              <!-- <v-card> -->
              <v-card class="pa-6 bg-dark elevation-3">
                <v-card-text>
                  <div class="d-flex align-center justify-space-between">
                    <div>
                      <h3 class="fs-6">Upload Media for Ads</h3>
                      <!-- <p>Send Private Message to a User</p> -->
                    </div>
                    <div>
                      <!-- <v-img src="../../assets/close-btn.png" width="40" height="40"></v-img> -->
                      <v-btn icon="mdi-close" @click="dialog2 = false"></v-btn>
                    </div>
                  </div>
                  <v-form class="mt-2" @submit.prevent="uploadAds">
                    <v-row>
                      <v-col cols="12">
                        <p class="mb-3">Name of Ad</p>
                        <v-text-field
                          variant="solo"
                          v-model="addAds.name"
                          placeholder="Enter name of Ads"
                        ></v-text-field>
                        <!-- <input type="file"/> -->
                      </v-col>
                      <v-col cols="12">
                        <p class="mb-3">Video Url</p>
                        <!-- <input type="file" /> -->
                        <v-text-field
                          variant="solo"
                          v-model="addAds.url"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <p class="mb-3">Type</p>
                        <v-select
                          variant="solo"
                          :items="AdsTypes"
                          v-model="addAds.type"
                          placeholder="Enter AD Title"
                        ></v-select>
                      </v-col>
                      <v-col cols="12">
                        <p class="mb-3">Rewards</p>
                        <v-text-field
                          variant="solo"
                          v-model="addAds.reward"
                          placeholder="Enter Rewards"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <p class="mb-3">End Date</p>
                        <v-text-field
                          variant="solo"
                          v-model="addAds.to"
                          type="date"
                          placeholder="Enter End Date"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12">
                        <p class="mb-3">Status</p>
                        <v-switch
                          v-model="addAds.is_active"
                          hide-details
                          hint="Enter a true or false Statement"
                        ></v-switch>
                      </v-col>
                      <v-col cols="12">
                        <v-btn type="submit" color="primary" block size="large"
                          >Upload</v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-card>
        </v-col>
        <v-col cols="12" lg="6" class="" v-for="ad in adsDetails" :key="ad.id">
          <v-card class="bg-dark-5">
            <v-row>
              <v-col cols="6" class="align-stretch">
                <v-img
                  src="../../assets/blogg.svg"
                  width="250"
                  height="280"
                  cover
                >
                  <template v-slot:placeholder>
                    <div class="d-flex align-center justify-center fill-height">
                      <v-progress-circular
                        color="grey-lighten-4"
                        indeterminate
                      ></v-progress-circular>
                    </div>
                  </template>
                </v-img>
              </v-col>
              <v-col cols="6">
                <div class="pa-2">
                  <p class="fs-6">{{ ad.name }}</p>
                  <div class="mt-3">
                    <v-dialog v-model="dialog5" width="800" opacity="-0.5">
                      <template v-slot:activator="{ props }">
                        <v-btn
                          color="primary"
                          v-bind="props"
                          size="small"
                          class="ma-2"
                          variant="tonal"
                          @click="getSingleAdDetail(ad.id)"
                        >
                          View
                        </v-btn>
                      </template>
                      <!-- <v-card> -->
                      <v-card class="pa-6 bg-dark elevation-3">
                        <p class="text-end">
                          <!-- <v-img src="../../assets/close-btn.png" width="40" height="40"></v-img> -->
                          <v-btn
                            icon="mdi-close"
                            @click="dialog5 = false"
                          ></v-btn>
                        </p>
                        <v-card-text>
                          <p class="text-capitalize fs-4">
                            <span class="text-primary">More About:</span>
                            {{ singleAdDetails.name }}
                          </p>
                          <v-row>
                            <v-col cols="12">
                              <v-img src="../../assets/blogg.svg" cover></v-img>
                            </v-col>
                            <v-col cols="12">
                              <p
                                class="fs-4 text-capitalize font-weight-bolder"
                              >
                                {{ singleAdDetails.name }}
                              </p>
                              <p
                                class="fs-6 text-capitalize font-weight-medium mt-6"
                              >
                                {{ singleAdDetails.type }}
                              </p>
                              <p
                                class="fs-6 text-capitalize font-weight-medium mt-6"
                              >
                                {{ singleAdDetails.reward }}
                              </p>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-dialog>
                    <v-dialog v-model="dialog6" width="800" opacity="-0.5">
                      <template v-slot:activator="{ props }">
                        <v-btn
                          v-bind="props"
                          size="small"
                          class="ma-1"
                          variant="outlined"
                          @click="getSingleAdDetail(ad.id)"
                        >
                          Edit
                        </v-btn>
                      </template>
                      <!-- <v-card> -->
                      <v-card class="pa-6 bg-dark elevation-3">
                        <p class="text-end">
                          <!-- <v-img src="../../assets/close-btn.png" width="40" height="40"></v-img> -->
                          <v-btn
                            icon="mdi-close"
                            @click="dialog6 = false"
                          ></v-btn>
                        </p>
                        <v-card-text>
                          <v-form>
                            <v-row>
                              <v-col cols="12">
                                <p class="mb-3">Ad Title</p>
                                <v-text-field
                                  variant="solo"
                                  placeholder="Enter Blog Title"
                                  v-model="singleAdDetails.name"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12">
                                <p class="mb-3">Ad Type</p>

                                <v-select
                                  :items="AdsTypes"
                                  variant="solo"
                                  placeholder="Enter Description"
                                  v-model="singleAdDetails.type"
                                ></v-select>
                              </v-col>
                              <v-col cols="12">
                                <p class="mb-3">Reward</p>
                                <v-text-field
                                  variant="solo"
                                  v-model="singleAdDetails.reward"
                                >
                                </v-text-field>
                              </v-col>
                              <v-col cols="12">
                                <p class="mb-3">Video Url</p>
                                <v-text-field
                                  variant="solo"
                                  v-model="singleAdDetails.url"
                                >
                                </v-text-field>
                              </v-col>

                              <v-col cols="12">
                                <p class="mb-3">End Date</p>
                                <v-text-field
                                  variant="solo"
                                  type="date"
                                  v-model="singleAdDetails.to"
                                >
                                </v-text-field>
                              </v-col>
                              <v-col cols="12">
                                <p class="mb-3">Status</p>
                                <v-switch
                                  v-model="singleAdDetails.is_active"
                                  hide-details
                                ></v-switch>
                              </v-col>
                              <v-col cols="12">
                                <v-btn
                                  type="submit"
                                  color="primary"
                                  block
                                  size="large"
                                  @click.prevent="updateSingleAdDetail(ad.id)"
                                  >Update</v-btn
                                >
                              </v-col>
                            </v-row>
                          </v-form>
                        </v-card-text>
                      </v-card>
                    </v-dialog>
                    <v-btn
                      variant="text"
                      size="small"
                      class="ma-1"
                      color="red"
                      @click.prevent="deleteAd(ad.id)"
                      >Delete</v-btn
                    >
                  </div>
                  <p class="text-dark-2 mt-4">Uploaded by Muburak Ogunlabi</p>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </AdminLayout>
</template>
<script setup>
import AdminLayout from "../../components/AdminLayout.vue"
import { ref, reactive, onMounted } from "vue"
import axiosInstance from "../../plugins/axios"

// const isDragging = ref(false);
// const files = ref([]);

const overlay = ref(true)
const dialog = ref(false)
const dialog2 = ref(false)
const dialog3 = ref(false)
const dialog4 = ref(false)

const dialog5 = ref(false)
const dialog6 = ref(false)

const blogs = ref(true)
const Ads = ref(false)

const blogDetails = ref([])
const singleBlogDetails = ref([])

const adsDetails = ref([])
const singleAdDetails = ref([])

const snackbar = reactive({
  status: false,
  msg: "",
  color: "",
  timeout: 5000,
})

const addBlogs = reactive({
  name: "",
  description: "",
  is_active: "",
  image: null,
})
const addAds = reactive({
  name: "",
  type: null,
  reward: "",
  url: "",
  status: "",
  to: "",
})

const AdsTypes = ref(["video", "content"])

function uploadAds() {
  axiosInstance
    .post(`/admin/adverts`, addAds)
    .then((response) => {
      snackbar.status = true
      snackbar.color = "green"
      snackbar.msg = response.data.message
      dialog2.value = false
      getAds()
    })
    .catch((error) => {
      snackbar.status = true
      snackbar.msg = error.response.data.message
      snackbar.color = "red"
    })
}

function showAds() {
  blogs.value = false
  Ads.value = true
  getAds()
}

function getAds() {
  axiosInstance
    .get(`/admin/adverts`)
    .then((response) => {
      adsDetails.value = response.data.data
      // console.log(adsDetails.value)
      overlay.value = false
    })
    .catch((error) => {
      console.log(error)
    })
}

function getSingleAdDetail(id) {
  axiosInstance
    .get(`/admin/adverts/${id}`)
    .then((response) => {
      console.log(response.data)
      singleAdDetails.value = response.data
    })
    .catch((error) => {
      console.log(error)
    })
}

function updateSingleAdDetail(id) {
  let payload = {
    name: singleAdDetails.value.name,
    reward: singleAdDetails.value.reward,
    url: singleAdDetails.value.url,
    is_active: singleAdDetails.value.is_active,
    to: singleAdDetails.value.to,
  }
  axiosInstance
    .put(`/admin/adverts/${id}`, payload)
    .then((response) => {
      snackbar.status = true
      snackbar.color = "green"
      snackbar.msg = response.data.message
      dialog6.value = false
      getAds()
    })
    .catch((error) => {
      console.log(error)
      snackbar.status = true
      snackbar.msg = error.response.data.message
      snackbar.color = "red"
    })
}

function deleteAd(id) {
  axiosInstance
    .delete(`/admin/adverts/${id}`)
    .then((response) => {
      snackbar.status = true
      snackbar.color = "green"
      snackbar.msg = response.data.message
      getAds()
    })
    .catch((error) => {
      snackbar.status = true
      snackbar.msg = error.response.data.message
      snackbar.color = "red"
    })
}

// blogs
function uploadBlogs() {
  axiosInstance
    .post(`/admin/blogs`, addBlogs)
    .then((response) => {
      snackbar.status = true
      snackbar.color = "green"
      snackbar.msg = response.data.message

      dialog.value = false
      dialog2.value = false
      getBlogs()
    })
    .catch((error) => {
      snackbar.status = true
      snackbar.msg = error.response.data.message
      snackbar.color = "red"
      dialog.value = false
      dialog2.value = false
    })
}

function getBlogs() {
  axiosInstance
    .get(`/admin/blogs`)
    .then((response) => {
      blogDetails.value = response.data.data
      overlay.value = false
      // console.log(blogDetails.value)
    })
    .catch((error) => {
      console.log(error)
    })
}

function getSingleBlogDetail(id) {
  axiosInstance
    .get(`/admin/blogs/${id}`)
    .then((response) => {
      console.log(response.data.data)
      singleBlogDetails.value = response.data
    })
    .catch((error) => {
      console.log(error)
    })
}

function updateSingleBlogDetail(id) {
  let payload = {
    name: singleBlogDetails.value.name,
    description: singleBlogDetails.value.description,
    image: singleBlogDetails.value.image,
    is_active: singleBlogDetails.value.is_active,
  }
  axiosInstance
    .put(`/admin/blogs/${id}`, payload)
    .then((response) => {
      snackbar.status = true
      snackbar.color = "green"
      snackbar.msg = response.data.message
      dialog4.value = false
      getBlogs()
    })
    .catch((error) => {
      console.log(error)
      snackbar.status = true
      snackbar.msg = error.response.data.message
      snackbar.color = "red"
    })
}

function deleteBlog(id) {
  axiosInstance
    .delete(`/admin/blogs/${id}`)
    .then((response) => {
      snackbar.status = true
      snackbar.color = "green"
      snackbar.msg = response.data.message
      getBlogs()
    })
    .catch((error) => {
      snackbar.status = true
      snackbar.msg = error.response.data.message
      snackbar.color = "red"
    })
}

function showBlogs() {
  blogs.value = true
  Ads.value = false
  getBlogs()
}

onMounted(() => {
  getBlogs()
  getAds()
})
</script>
<style scoped>
* {
  /* outline: 1px solid green; */
  font-family: "Nunito", sans-serif;
}

.queryBtn {
  padding: 0.7rem 1.6rem;
  /* border:1px solid #555555; */
  border: 1px solid rgba(255, 255, 255, 0.08);
  margin: 0.8rem;
  border-radius: 4px;
  font-size: 0.95rem;
  color: rgba(255, 255, 255, 0.25);
  box-shadow: 2px 2px 2px solid #555;
}

.queryBtn:hover {
  border: 1px solid rgba(255, 255, 255, 0.68);
  color: rgba(255, 255, 255, 0.68);
}

.queryBtn.active {
  /* Active state styles */
  border: 1px solid rgba(255, 255, 255, 0.68);
  color: rgba(255, 255, 255, 0.68);
}
.fs-6 {
  font-size: 1.5rem;
}

.fs-4 {
  font-size: 2.3rem;
}

.dropzone {
  /* Your styling for the drop zone */
  border: 2px dashed #ccc;
  padding: 20px;
  text-align: center;
}

.dropzone.active {
  /* Styling for when the drop zone is active */
  border-color: #333;
}
</style>
