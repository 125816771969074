<template>
  <AdminLayout page="User Details">
     <div v-if="overlay">
      <DataTable />
    </div>
    <v-container>
      <v-row>
        <v-col cols="12" class="d-flex justify-end">
          <div class="d-flex">
            <v-btn class="elevation-0" color="gray">
              Deactivate User
              <v-dialog
                v-model="dialog"
                activator="parent"
                width="600"
                opacity="-0.5"
                transition="dialog-top-transition"
              >
                <v-card class="pa-5 bg-dark elevation-3">
                  <div class="d-flex align-center justify-space-between">
                    <div>
                      <h3 class="fs-5">Deactivate User</h3>
                      <p>Fund User's Wallet</p>
                    </div>
                    <div>
                      <!-- <v-img src="../../assets/close-btn.png" width="40" height="40"></v-img> -->
                      <v-btn icon="mdi-close" @click="dialog = false"></v-btn>
                    </div>
                  </div>
                  <v-form class="mt-5">
                    <v-row>
                      <v-col cols="12">
                        <p class="mb-3">Select User</p>
                        <v-autocomplete
                          variant="solo"
                          placeholder="Enter User ID, name to search for user"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12">
                        <v-btn type="submit" color="primary" block size="large"
                          >Deactivate</v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card>
              </v-dialog>
            </v-btn>
            <v-btn class="elevation-0 ms-1 ms-lg-3" color="primary">
              Fund Token Wallet
              <v-dialog
                v-model="dialog2"
                activator="parent"
                width="600"
                opacity="-0.5"
                transition="dialog-top-transition"
              >
                <v-card class="pa-5 bg-dark elevation-3">
                  <div class="d-flex align-center justify-space-between">
                    <div>
                      <h3 class="fs-5">Fund Token Wallet</h3>
                      <p>Fund User's Wallet</p>
                    </div>
                    <div>
                      <!-- <v-img src="../../assets/close-btn.png" width="40" height="40"></v-img> -->
                      <v-btn icon="mdi-close" @click="dialog2 = false"></v-btn>
                    </div>
                  </div>
                  <v-form class="mt-5">
                    <v-row>
                      <v-col cols="12">
                        <p class="mb-3">Token Amount</p>
                        <v-text-field
                          variant="solo"
                          placeholder="Enter token amount"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-btn type="submit" color="primary" block size="large"
                          >Fund Wallet</v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card>
              </v-dialog>
            </v-btn>
          </div>
        </v-col>
        <v-col cols="12" lg="7" class="mt-3">
          <v-card class="pa-4 d-flex bg-dark-5">
            <v-row>
              <v-col cols="12" lg="4">
                <div>
                  <v-img
                    src="../../assets/single-user.svg"
                    width="200"
                    height="200"
                  ></v-img>
                </div>
              </v-col>
              <v-col cols="8">
                <div class="mt-7">
                  <h3 class="fs-5">{{ singleDetail.fullname }}</h3>
                  <p class="text-dark-2">@{{ singleDetail.username }}</p>

                  <v-row class="mt-1">
                    <v-col cols="12" lg="5">
                      <div class="d-flex mt-7">
                        <div>
                          <v-img
                            src="../../assets/call.svg"
                            width="20"
                            height="30"
                          ></v-img>
                        </div>
                        <span class="text-dark-2 fs-xs">
                          {{ singleDetail.phone }}</span
                        >
                      </div>
                    </v-col>
                    <v-col cols="12" lg="4">
                      <div class="d-flex mt-0 mt-lg-6">
                        <div class="">
                          <v-img
                            src="../../assets/directnotification.svg"
                            width="20"
                            height="30"
                          ></v-img>
                        </div>
                        <span class="text-dark-2 ms-1 fs-xs mt-1">{{
                          singleDetail.email
                        }}</span>
                      </div>
                    </v-col>
                  </v-row>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="12" lg="5" class="mt-5 border border-dark-2">
          <v-row class="mt-4">
            <v-col
              cols="4"
              class="border border-dark-2 border-t-0 border-e-0 border-s-0"
            >
              <h3 class="text-dark-2">Total Tokens</h3>
              <h2>
                {{
                  singleDetail.token
                    ? getFormattedAmount(singleDetail.token)
                    : "0"
                }}
              </h2>
            </v-col>
            <v-col
              cols="4"
              class="border border-dark-2 border-t-0 border-e-0 pa-4"
            >
              <h3 class="text-dark-2">Total Coins</h3>
              <h2>
                {{
                  singleDetail.coin
                    ? getFormattedAmount(singleDetail.coin)
                    : "0"
                }}
              </h2>
            </v-col>
            <v-col
              cols="4"
              class="border border-dark-2 border-t-0 border-e-0 pa-4"
            >
              <h3 class="text-dark-2">Games Played</h3>
              <h2>0</h2>
            </v-col>
            <v-col
              cols="4"
              class="border border-dark-2 border-t-0 border-e-0 border-b-0 border-s-0 pa-4"
            >
              <h3 class="text-dark-2">Games Won</h3>
              <h2>0</h2>
            </v-col>
            <v-col
              cols="4"
              class="border border-dark-2 border-t-0 border-e-0 border-b-0 pa-4"
            >
              <h3 class="text-dark-2">Games Lost</h3>
              <h2>0</h2>
            </v-col>
            <v-col
              cols="4"
              class="border border-dark-2 border-t-0 border-e-0 border-b-0 pa-4"
            >
              <h3 class="text-dark-2">Leaderboard Pos</h3>
              <h2>12th</h2>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <div class="row-scroller mt-5">
        <v-row class="row">
          <v-col cols="12" lg="12" class="bg-dark-3">
            <div class="d-flex justify-center">
              <v-btn
                variant="outlined"
                class="ma-3"
                @click="getSingleUserGamesPlayed"
                >Games Played</v-btn
              >
              <v-btn
                variant="outlined"
                class="ma-3"
                @click="getSingleUserWithdrawals"
                >Withdrawals</v-btn
              >
              <v-btn
                variant="outlined"
                class="ma-3"
                @click="getSingleUserDeposit"
                >Deposits</v-btn
              >
              <v-btn variant="outlined" class="ma-3">Token from Ads</v-btn>
            </div>
          </v-col>

          <v-col cols="12">
            <div v-if="gamesPlayed">
              <v-data-table
                :headers="headersGames"
                :items="singleDetailGames"
                class="elevation-2 bg-dark-5 text-dark-6 font-weight-bold pa-3 shadow-lg"
              >
                <template v-slot:top> </template>
                <template v-slot:[`item.created_at`]="{ item }">
                  <span>{{ getFormattedTime(item.created_at) }}</span>
                </template>
                <template v-slot:[`item.game_type`]="{ item }">
                  <span class="text-capitalize">{{ item.game_type }}</span>
                </template>
                <template v-slot:[`item.amount`]="{ item }">
                  <span>₦{{ getFormattedAmount(item.amount) }}</span>
                </template>
                <template #bottom></template>
              </v-data-table>
              <v-pagination :length="4" class="mt-4"></v-pagination>
            </div>

            <div v-if="withdrawals">
              <v-data-table
                :headers="headersWithdrawals"
                :items="singleDetailWithdrawal"
               class="elevation-2 bg-dark-5 text-dark-6 font-weight-bold pa-3 shadow-lg"
              >
                <template v-slot:top> </template>
                <template #bottom></template>
              </v-data-table>
              <v-pagination :length="4" class="mt-4"></v-pagination>
            </div>

            <div v-if="deposits">
              <v-data-table
                :headers="headersDeposit"
                :items="singleDetailDeposit"
                class="elevation-2 bg-dark-5 text-dark-6 font-weight-bold pa-3 shadow-lg"
              >
                <template v-slot:top> </template>
                <template #bottom></template>
              </v-data-table>
              <v-pagination :length="4" class="mt-4"></v-pagination>
            </div>
            <div v-if="tokenFromAds">
              <v-data-table
                :headers="headers"
                :items="items"
                class="elevation-2 bg-dark-5 text-dark-6 font-weight-bold pa-3 shadow-lg"
              >
                <template v-slot:top> </template>
                <template #bottom></template>
              </v-data-table>
              <v-pagination :length="4" class="mt-4"></v-pagination>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </AdminLayout>
</template>
<script setup>
import AdminLayout from "../../components/AdminLayout.vue";

import axiosInstance from "../../plugins/axios";

import { ref, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";

import DataTable from "../../components/DataTable.vue"

const overlay = ref(true);

const dialog = ref(false);
const dialog2 = ref(false);
const items = ref([]);
const singleDetail = ref([]);
const singleDetailGames = ref([]);
const singleDetailDeposit = ref([]);
const singleDetailWithdrawal = ref([]);

const gamesPlayed = ref(true);
const withdrawals = ref(false);
const deposits = ref(false);
const tokenFromAds = ref(false);

const route = useRoute();
const userId = route.params.user_id;

// const tab = ref(null);

const headers = ref([
  {
    title: "Opponent",
    align: "start",

    key: "name",
  },

  { title: "Game Type", align: "start", key: "email" },
  { title: "Outcome", align: "start", key: "phone_no" },
  { title: "Amount Staked", align: "start", key: "state" },
  { title: "Revenue", align: "start", key: "state" },
  { title: "Date", align: "start", key: "state" },
]);
const headersGames = ref([
  {
    title: "Opponent",
    align: "start",

    key: "name",
  },

  { title: "Game Type", align: "start", key: "game_type" },
  { title: "Outcome", align: "start", key: "phone_no" },
  { title: "Amount Staked", align: "start", key: "amount" },
  { title: "Revenue", align: "start", key: "amount" },
  { title: "Date", align: "start", key: "created_at" },
]);
const headersDeposit = ref([
  {
    title: "Amount",
    align: "start",

    key: "amount",
  },

  { title: "Bank Name", align: "start", key: "game_type" },
  { title: "Account Number", align: "start", key: "phone_no" },
  { title: "Account Name", align: "start", key: "amount" },
  { title: "Status", align: "start", key: "is_active" },
  { title: "Date", align: "start", key: "created_at" },
]);
const headersWithdrawals = ref([
  {
    title: "Amount",
    align: "start",

    key: "amount",
  },

  { title: "Bank Name", align: "start", key: "game_type" },
  { title: "Account Number", align: "start", key: "phone_no" },
  { title: "Account Name", align: "start", key: "amount" },
  { title: "Status", align: "start", key: "amount" },
  { title: "Date", align: "start", key: "created_at" },
]);

function getSingleUserData() {
  axiosInstance
    .get(`/admin/users/${userId}`)
    .then((response) => {
      singleDetail.value = response.data.data;
      overlay.value = false

      // console.log(singleDetail.value, "55555");
    })
    .catch((error) => {
      console.log(error);
    });
}

function getSingleUserGamesPlayed() {
  singleDetailGames.value = [];
  singleDetailDeposit.value = [];
  singleDetailWithdrawal.value = [];

  gamesPlayed.value = true;
  withdrawals.value = false;
  deposits.value = false;
  axiosInstance
    .get(`/admin/users/${userId}/games`)
    .then((response) => {
      // console.log(response.data.data)
      singleDetailGames.value = response.data;
      overlay.value = false

      console.log(singleDetailGames.value);
    })
    .catch((error) => {
      console.log(error);
    });
}
function getSingleUserDeposit() {
  singleDetailGames.value = [];
  singleDetailDeposit.value = [];
  singleDetailWithdrawal.value = [];

  deposits.value = true;
  withdrawals.value = false;
  gamesPlayed.value = false;
  axiosInstance
    .get(`/admin/users/${userId}/histories?transaction_type=deposit`)
    .then((response) => {
      // console.log(response.data.data)
      singleDetailDeposit.value = response.data;
      overlay.value = false

      console.log(singleDetailDeposit.value);
    })
    .catch((error) => {
      console.log(error);
    });
}
function getSingleUserWithdrawals() {
  singleDetailGames.value = [];
  singleDetailDeposit.value = [];
  singleDetailWithdrawal.value = [];

  withdrawals.value = true;
  gamesPlayed.value = false;
  deposits.value = false;
  axiosInstance
    .get(`/admin/users/${userId}/histories?transaction_type=withdrawal`)
    .then((response) => {
      // console.log(response.data.data)
      singleDetailWithdrawal.value = response.data;
      overlay.value = false

    })
    .catch((error) => {
      console.log(error);
    });
}

function getFormattedTime(time) {
  const date = new Date(time); // Parse the date string into a Date object

  // Extract the day, month, year, hours, and minutes
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are  0-indexed
  const year = date.getFullYear();
  const hours = ((date.getHours() + 11) % 12) + 1; // Convert to  12-hour format
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const period = date.getHours() >= 12 ? "PM" : "AM"; // Determine AM/PM

  // Construct the formatted date string
  const formattedDate = `${day}/${month}/${year} ${hours}:${minutes}${period}`;

  return formattedDate;
}

function getFormattedAmount(amount) {
  return new Intl.NumberFormat("en-US").format(amount);
}

onMounted(() => {
  getSingleUserData();
  getSingleUserGamesPlayed();
  // getSingleUserDeposit();
});
</script>
<style scoped>
* {
  /* outline: 1px solid green; */
  font-family: "Nunito", sans-serif;
}

.fs-5 {
  font-size: 2rem;
}
.fs-xs {
  font-size: 0.95rem;
}
</style>