<template>
  <content-loader
   
   
    viewBox="0 0 400 400"
    :speed="2"
    primaryColor="#0f0f0f"
     secondaryColor="#3C3C3C"
  >
     <rect x="5" y="10" rx="5" ry="5" width="120" height="50" />
      <rect x="140" y="10" rx="5" ry="5" width="100" height="50" />
      <rect x="250" y="10" rx="5" ry="5" width="145" height="110" />
      <br/>
      <rect x="5" y="70" rx="5" ry="5" width="120" height="50" />
      <rect x="140" y="70" rx="5" ry="5" width="100" height="50" />
      
      


      <rect x="10" y="140" rx="3" ry="3" width="250" height="10" />
  <rect x="10" y="160" rx="3" ry="3" width="220" height="10" />
  <rect x="10" y="180" rx="3" ry="3" width="170" height="10" />
  <rect x="10" y="200" rx="3" ry="3" width="250" height="10" />
  <rect x="10" y="220" rx="3" ry="3" width="200" height="10" />
  <rect x="10" y="240" rx="3" ry="3" width="80" height="10" />
     
  </content-loader>
</template>

<script>
import { ContentLoader } from "vue-content-loader"
export default {
  components: { ContentLoader },
}
</script>

<style></style>

