import { createStore } from 'vuex'

export default createStore({
  state: {
    user: JSON.parse(localStorage.getItem("user")),
  },

  getters: {
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
  },
  actions: {
  },
  modules: {
  }
})
