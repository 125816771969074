<template>
  <AdminLayout page="Dashboard">
    <div v-if="overlay">
      <DashboardSkeletonLoader />
    </div>
    <v-container fluid v-else>
      <v-row>
        <v-col cols="12" md="8" lg="9">
          <p class="mt-0 text-darker-2">
            Quick Summary of all the information on this platform
          </p>

          <div class="row-scroller mt-5">
            <v-row class="row rounded">
              <v-col cols="12" lg="12" class="">
                <div class="d-flex rounded justify-center bg-dark-5 py-3">
                  <div class="d-flex align-center justify-space-between">
                    <button
                      class="queryBtn"
                     :class="{ 'active': isAllDataActive }"
                      @click.prevent="initAllData"
                    >
                      All Time Data
                    </button>
                    <button
                      class="queryBtn"
                      :class="{ 'active': isTodayActive }"
                      @click.prevent="initTodayData"
                    >
                      Today's Data
                    </button>
                    <button
                      class="queryBtn"
                      :class="{ 'active': isWeekActive }"
                      @click.prevent="initWeekData"
                    >
                      This Week's Data
                    </button>
                    <button
                      class="queryBtn"
                      :class="{ 'active': isMonthActive }"
                      @click.prevent="initMonthData"
                    >
                      All Month Data
                    </button>
                    <button
                      class="queryBtn d-flex align-center"
                      @click="showDate"
                    >
                      <v-img
                        src="../../assets/calendar.svg"
                        width="30"
                        height="20"
                        class="me-1"
                      ></v-img
                      >Select Period
                    </button>
                  </div>
                </div>
              </v-col>
            </v-row>
          </div>

          <div v-if="monthFields">
            <v-row class="mt-3">
              <v-col cols="6">
                <p class="mb-1">Start Month</p>
                <v-text-field
                  variant="outlined"
                  v-model="startMonth"
                  type="date"
                  class="me-3 rounded"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <p class="mb-1">End Month</p>

                <v-text-field
                  variant="outlined"
                  v-model="endMonth"
                  type="date"
                  @input="filterByDate"
                  class="rounded"
                ></v-text-field>
              </v-col>
            </v-row>
          </div>

          <v-row class="mt-6">
            <v-col cols="12" md="6" lg="4" class="w-100">
              <div class="first-box pa-4">
                <v-img
                  src="../../assets/first-dashboard-box-icon.png"
                  width="40"
                  class=""
                ></v-img>
                <h3 class="mt-4 font-weight-light">Total Transactions</h3>
                <h2 class="mt-3 fs-6">
                  {{ dashboardOverview.total_transaction }}
                </h2>
              </div>
            </v-col>
            <v-col cols="12" md="6" lg="4">
              <div class="second-box pa-4">
                <v-img
                  src="../../assets/second-dashboard-box-icon.png"
                  width="40"
                  class=""
                ></v-img>
                <h3 class="mt-4 font-weight-light">Total Revenue Generated</h3>
                <h2 class="mt-3 fs-6">
                  ₦{{
                    dashboardOverview.total_revenue
                      ? getFormattedAmount(dashboardOverview.total_revenue)
                      : "0"
                  }}
                </h2>
              </div>
            </v-col>
            <v-col cols="12" md="6" lg="4">
              <div class="third-box pa-4">
                <v-img
                  src="../../assets/third-dashboard-box-icon.png"
                  width="40"
                  class=""
                ></v-img>
                <h3 class="mt-4 font-weight-light">Total No of Users</h3>
                <h2 class="mt-3 fs-6">
                  {{
                    dashboardOverview.total_users
                      ? getFormattedAmount(dashboardOverview.total_users)
                      : "0"
                  }}
                </h2>
              </div>
            </v-col>
            <v-col cols="12" md="6" lg="4">
              <div class="fourth-box pa-4">
                <v-img
                  src="../../assets/fourth-dashboard-box-icon.png"
                  width="40"
                  class=""
                ></v-img>
                <h3 class="mt-4 font-weight-light">Total Games Played</h3>
                <h2 class="mt-3 fs-6">
                  {{
                    dashboardOverview.total_game_count
                      ? getFormattedAmount(dashboardOverview.total_game_count)
                      : "0"
                  }}
                </h2>
              </div>
            </v-col>
            <v-col cols="12" md="6" lg="4">
              <div class="fifth-box pa-4">
                <v-img
                  src="../../assets/fifth-dashboard-box-icon.png"
                  width="40"
                  class=""
                ></v-img>
                <h3 class="mt-4 font-weight-light">Total No of Tokens</h3>
                <h2 class="mt-3 fs-6">
                  {{
                    dashboardOverview.total_token
                      ? getFormattedAmount(dashboardOverview.total_token)
                      : "0"
                  }}
                </h2>
              </div>
            </v-col>
            <v-col cols="12" md="6" lg="4">
              <div class="sixth-box pa-4">
                <v-img
                  src="../../assets/sixth-dashboard-box-icon.png"
                  width="40"
                  class=""
                ></v-img>
                <h3 class="mt-4 font-weight-light">Total No of Coins</h3>
                <h2 class="mt-3 fs-6">
                  {{
                    dashboardOverview.total_coin
                      ? getFormattedAmount(dashboardOverview.total_coin)
                      : "0"
                  }}
                </h2>
              </div>
            </v-col>
          </v-row>

          <div class="mt-7">
            <div class="d-flex justify-space-between align-center mb-3">
              <p class="fs-3 font-weight-bold">Recent Games</p>

              <v-btn
                variant="text"
                class="text-primary-2 text-capitalize font-weight-bold"
                color="primary"
                :to="{ name: 'AdminGames' }"
                >View All Games</v-btn
              >
            </div>
            <v-data-table
              :headers="headers"
              :items="items"
              class="elevation-1 bg-dark-5 text-dark-6 font-weight-bold pa-3 shadow-lg"
            >
              <template v-slot:top> </template>

              <template v-slot:item.game_users[0].amount="{ item }">
                <span class="text-capitalize"
                  >₦{{ getFormattedAmount(item.game_users[0].amount) }}</span
                >
              </template>
              <template v-slot:[`item.game_type`]="{ item }">
                <span class="text-capitalize">{{ item.game_type }}</span>
              </template>
              <template #bottom></template>
            </v-data-table>
            <!-- <v-pagination :length="4"></v-pagination> -->
          </div>

          <div class="mt-7">
            <div class="d-flex justify-space-between align-center mb-3">
              <p class="fs-3 font-weight-bold">Recent Transactions</p>

              <v-btn
                variant="text"
                class="text-primary-2 text-capitalize font-weight-bold"
                color="primary"
                :to="{ name: 'Transaction' }"
                >View All Transactions</v-btn
              >
            </div>
            <v-data-table
              :headers="headers2"
              :items="items2"
              class="elevation-1 bg-dark-5 text-dark-6 font-weight-bold pa-3 shadow-lg"
            >
              <template v-slot:top> </template>
              <template v-slot:[`item.amount`]="{ item }">
                <span>₦{{ getFormattedAmount(item.amount) }}</span>
              </template>
              <template v-slot:[`item.transaction_type`]="{ item }">
                <span class="text-capitalize">{{ item.transaction_type }}</span>
              </template>
              <template v-slot:[`item.created_at`]="{ item }">
                <span>{{ getFormattedTime(item.created_at) }}</span>
              </template>
              <template #bottom></template>
            </v-data-table>
            <!-- <v-pagination :length="4"></v-pagination> -->
          </div>
        </v-col>

        <v-col
          cols="12"
          md="4"
          lg="3"
          class="border-dark-5 border-t-0 border-b-0 border-e-0"
        >
          <div class="d-flex justify-space-between align-center">
            <p class="fs-3">Recent Chats</p>
            <v-btn
              variant="text"
              class="text-primary mb-2 text-capitalize"
              :to="{ name: 'AdminChat' }"
              >View All</v-btn
            >
          </div>

          <div class="pa-2 bg-dark rounded">
            <div class="ps-sticky">
              <p class="text-uppercase text-darker-2 mt-2">Today</p>

              <v-table density="comfortable" class="bg-dark pa-2">
                <tbody>
                  <tr
                    :key="index"
                    v-for="(item, index) in chatsData"
                    v-show="index < 4"
                    class="cursor-pointer"
                  >
                    <div class="mt-2 d-flex justify-space-between my-5 rounded">
                      <div>
                        <div class="d-flex">
                          <v-img
                            src="../../assets/bolajiAmusan.svg"
                            width="45"
                            height="50"
                          ></v-img>
                          <div class="ms-2">
                            <p class="fs-sm">
                              @{{
                                item.data.username
                                  ? item.data.username
                                  : "Nameless"
                              }}
                            </p>
                            <p class="fs-xs mt-2 text-capitalize text-dark-2">
                              {{
                                item.data.message
                                  ? item.data.message
                                  : "No Message"
                              }}
                            </p>
                          </div>
                        </div>
                      </div>

                      <div>
                        <p class="text-primary">
                          {{
                            item.created_at
                              ? formatTime(item.created_at)
                              : "No set Time"
                          }}
                        </p>
                      </div>
                    </div>
                  </tr>
                </tbody>
              </v-table>
            </div>
          </div>

          <!-- <div>
            <p class="text-uppercase text-darker-2 mt-6">Yesterday</p>

            <div class="mt-2 d-flex justify-space-between">
              <div>
                <v-img
                  src="../../assets/chat3.png"
                  width="45"
                  height="50"
                ></v-img>
              </div>
              <div>
                <p class="fs-sm text-darker">@TheWarrior123</p>
                <p class="fs-xs mt-2 text-darker">
                  I have not been able to withdraw my...
                </p>
              </div>
              <div>
                <p class="text-darker">6:00pm</p>
              </div>
            </div>
            <div class="mt-2 d-flex justify-space-between mt-3">
              <div>
                <v-img
                  src="../../assets/chat4.png"
                  width="45"
                  height="50"
                ></v-img>
              </div>
              <div>
                <p class="fs-sm text-darker">@TheWarrior123</p>
                <p class="fs-xs text-darker mt-2">
                  I have not been able to withdraw my...
                </p>
              </div>
              <div>
                <p class="text-darker">6:00pm</p>
              </div>
            </div>
          </div>
          <div>
            <p class="text-uppercase text-darker-2 mt-6">Wednesday</p>

            <div class="mt-2 d-flex justify-space-between">
              <div>
                <v-img
                  src="../../assets/chat5.png"
                  width="45"
                  height="50"
                ></v-img>
              </div>
              <div>
                <p class="fs-sm text-darker">@TheWarrior123</p>
                <p class="fs-xs mt-2 text-darker">
                  I have not been able to withdraw my...
                </p>
              </div>
              <div>
                <p class="text-darker">6:00pm</p>
              </div>
            </div>
            <div class="mt-2 d-flex justify-space-between mt-3">
              <div>
                <v-img
                  src="../../assets/chat6.png"
                  width="45"
                  height="50"
                ></v-img>
              </div>
              <div>
                <p class="fs-sm text-darker">@TheWarrior123</p>
                <p class="fs-xs text-darker mt-2">
                  I have not been able to withdraw my...
                </p>
              </div>
              <div>
                <p class="text-darker">6:00pm</p>
              </div>
            </div>
          </div> -->

          <div class="mt-8">
            <div class="d-flex justify-space-between align-center mb-2">
              <p class="fs-3">Recent Users</p>
              <v-btn
                variant="text"
                class="text-primary mb-3 text-capitalize"
                :to="{ name: 'Users' }"
                >View All</v-btn
              >
            </div>

            <div class="bg-dark rounded">
              <div class="ps-sticky bg-dark-5 elevation-2 pa-1">
                <v-table density="comfortable" class="bg-dark-5">
                  <tbody>
                    <tr
                      :key="index"
                      v-for="(item, index) in userData"
                      v-show="index < 4"
                      class="cursor-pointer"
                    >
                      <div class="mt-2 d-flex justify-space-between my-5">
                        <div>
                          <div class="d-flex">
                            <v-img
                              src="../../assets/bolajiAmusan.svg"
                              width="45"
                              height="50"
                            ></v-img>
                            <div class="ms-1">
                              <p class="fs-sm">
                                {{ item.fullname ? item.fullname : "Nameless" }}
                              </p>
                              <p class="fs-xs mt-2 text-capitalize text-dark-2">
                                @{{
                                  item.username ? item.username : "No Username"
                                }}
                              </p>
                            </div>
                          </div>
                        </div>

                        <div>
                          <p class="text-primary fs-xs">24/7/2023 6:40PM</p>
                        </div>
                      </div>
                    </tr>
                  </tbody>
                </v-table>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </AdminLayout>
</template>
<script setup>
import AdminLayout from "../../components/AdminLayout.vue"
import axiosInstance from "../../plugins/axios"
import DashboardSkeletonLoader from "../../components/DashboardSkeletonLoader.vue"

import { ref, onMounted } from "vue"

const items = ref([])
const dashboardOverview = ref([])
const items2 = ref([])
const userData = ref([])
const chatsData = ref([])

const dialogDelete = ref(false)

const overlay = ref(true)

let date = ref("")

const monthFields = ref(false)

const isAllDataActive = ref(false)
const isWeekActive = ref(false)
const isTodayActive = ref(false)
const isMonthActive = ref(false)

const startMonth = ref("")
const endMonth = ref("")

date.value = new Date().getFullYear()

const headers = ref([
  {
    title: "Game",
    align: "start",
    searchable: true,
    key: "game_type",
  },

  { title: "Player 1", align: "start", key: "game_users[0].user.username" },
  { title: "Player 2", align: "start", key: "game_users[1].user.username" },
  { title: "Type", align: "start", key: "game_users[0].game_type" },
  { title: "Total Stake", align: "start", key: "game_users[0].amount" },
  { title: "Revenue", align: "start", key: "" },
])
const headers2 = ref([
  {
    title: "Player 1",
    align: "start",

    key: "sender.username",
  },

  // { title: "Category", align: "end", key: "transaction_type" },
  { title: "Type", align: "start", key: "transaction_type" },
  { title: "Amount", align: "start", key: "amount" },
  { title: "Time", align: "start", key: "created_at" },
])

function showDate() {
  monthFields.value = true
}

function getFormattedTime(time) {
  const date = new Date(time) // Parse the date string into a Date object

  // Extract the day, month, year, hours, and minutes
  const day = String(date.getDate()).padStart(2, "0")
  const month = String(date.getMonth() + 1).padStart(2, "0") // Months are  0-indexed
  const year = date.getFullYear()
  const hours = ((date.getHours() + 11) % 12) + 1 // Convert to  12-hour format
  const minutes = date.getMinutes().toString().padStart(2, "0")
  const period = date.getHours() >= 12 ? "PM" : "AM" // Determine AM/PM

  // Construct the formatted date string
  const formattedDate = `${day}/${month}/${year} ${hours}:${minutes}${period}`

  return formattedDate
}

function getFormattedAmount(amount) {
  return new Intl.NumberFormat("en-US").format(amount)
}

function formatTime(isoString) {
  const date = new Date(isoString)
  let hours = date.getUTCHours()
  const minutes = date.getUTCMinutes()
  const ampm = hours >= 12 ? "pm" : "am"

  hours = hours % 12
  hours = hours ? hours : 12 // the hour '0' should be '12'
  const minutesStr = minutes < 10 ? "0" + minutes : minutes
  const strTime = hours + ":" + minutesStr + ampm
  return strTime
}

async function initDashboardData() {
  try {
    const dashboardData = await axiosInstance.get(`/admin/dashboard/overview`)
    const recentEventsData = await axiosInstance.get(
      `/admin/dashboard/recent-events?type=games`
    )
    const transactionData = await axiosInstance.get(
      `/admin/dashboard/recent-events?type=transactions`
    )
    const usersData = await axiosInstance.get(`/admin/users`)
    const chatData = await axiosInstance.get(`/admin/notifications`)

    dashboardOverview.value = dashboardData.data.data
    items.value = recentEventsData.data.data
    items2.value = transactionData.data.data
    userData.value = usersData.data.data
    // console.log(userData.value)
    chatsData.value = chatData.data

    overlay.value = false
  } catch (e) {
    console.log(e)
    overlay.value = false
  }
}

function filterByDate() {
  overlay.value = true
  axiosInstance
    .get(
      `/admin/dashboard/overview?start_date=${startMonth.value}&end_date=${endMonth.value}`
    )
    .then((response) => {
      overlay.value = false

      // console.log(response)
      startMonth.value = ""
      endMonth.value = ""
      // monthFields.value = false
    })
    .catch((error) => {
      overlay.value = false

      // console.log(error)
    })
}

function initAllData() {
 filterByDate()

  isAllDataActive.value = true
  isMonthActive.value = false
  isWeekActive.value = false
  isTodayActive.value = false
}
function initWeekData() {
  filterByDate()


  isWeekActive.value = true
  isAllDataActive.value = false
  isMonthActive.value = false
  isTodayActive.value = false
}
function initMonthData() {
 filterByDate()


  isMonthActive.value = true
  isAllDataActive.value = false
  isWeekActive.value = false
  isTodayActive.value = false
}
function initTodayData() {
   filterByDate()


  isTodayActive.value = true
  isAllDataActive.value = false
  isMonthActive.value = false
  isWeekActive.value = false
}

onMounted(() => {
  initDashboardData()
})
</script>
<style scoped>
/* * {
  outline: 1px solid green;
} */

.queryBtn {
  padding: 0.5rem 1.2rem;
  /* border:1px solid #555555; */
  border: 1px solid rgba(255, 255, 255, 0.08);
  margin: 0.8rem;
  border-radius: 4px;
  font-size: 0.95rem;
  color: rgba(255, 255, 255, 0.25);
  box-shadow: 2px 2px 2px solid #555;
}
.queryBtn.active {
  /* Active state styles */
 border: 1px solid rgba(255, 255, 255, 0.68);
  color: rgba(255, 255, 255, 0.68);
}

.queryBtn:hover {
  border: 1px solid rgba(255, 255, 255, 0.68);
  color: rgba(255, 255, 255, 0.68);
}

/* Change the text color of the table cells */
.v-data-table .v-data-table__wrapper table tbody tr td {
  color: #4d4d4d !important;
}

.v-list-item--active {
  border-right: 2px solid #ff4c29; /* Adjust the color as needed */
}

.v-list-item--active .text-primary {
  color: #191919 !important;
}

.fs-6 {
  font-size: 2rem;
}

.fs-3 {
  font-size: 1.3rem;
}

.fs-xs {
  font-size: 0.75rem;
}
.fs-sm {
  font-size: 0.95rem;
}

.first-box {
  background: url("../../assets/purple-bg.png") no-repeat center center / cover;
  /* min-height: 40vh; */
  border-radius: 5px;
}
.second-box {
  background: url("../../assets/green-bg.png") no-repeat center center / cover;
  /* min-height: 40vh; */

  border-radius: 5px;
}
.third-box {
  background: url("../../assets/dark-orange.png") no-repeat center center /
    cover;
  /* min-height: 40vh; */
  border-radius: 5px;
}
.fourth-box {
  background: url("../../assets/pale-green.png") no-repeat center center / cover;
  /* min-height: 40vh; */
  border-radius: 5px;
}
.fifth-box {
  background: url("../../assets/dark-pink.png") no-repeat center center / cover;
  /* min-height: 40vh; */
  border-radius: 5px;
}
.sixth-box {
  background: url("../../assets/dark-purple.png") no-repeat center center /
    cover;
  /* min-height: 40vh; */
  border-radius: 5px;
}

.row-scroller {
  position: relative;
}

.row-scroller .row {
  display: flex;
  /* flex-wrap: wrap; */
  overflow-x: auto;
  scroll-behavior: smooth;
}

.v-btn {
  padding: 1rem 0 !important;
}
</style>
