<template>
  <AdminLayout page="Leader Board">
    <div v-if="overlay">
      <DataTable />
    </div>
    <v-container v-else>
      <p class="mt-0 text-dark-2">
        Quick Summary of all the information on the platform
      </p>


      <div class="row-scroller mt-5 ">
        <v-row class="row rounded">
          <v-col cols="12" lg="9" class="">
            <div class="d-flex bg-dark-5 rounded py-4">
              
             

              <div class="d-flex align-center justify-space-between">
                <!-- <div> -->

                  <v-btn variant="outlined" color="dark-2" class="mx-3" size="small"
                    >Today</v-btn
                  >
                <!-- </div> -->

                <v-btn variant="outlined" color="dark-2" class="mx-4" size="small"
                  >Yesterday</v-btn
                >

                <v-btn variant="outlined" color="dark-2" class=" mx-4" size="small"
                  >Friday</v-btn
                >
                <v-btn variant="outlined" color="dark-2" class=" mx-4" size="small"
                  >Thursday</v-btn
                >
                <v-btn variant="outlined" color="dark-2" class=" mx-4" size="small"
                  >Wednesday</v-btn
                >
                <v-btn variant="outlined" color="dark-2" class=" mx-4" size="small"
                  >Tuesday</v-btn
                >
                <v-btn variant="outlined" color="dark-2" class=" mx-4" size="small"
                  >Monday</v-btn
                >
              </div>
            </div>
          </v-col>
          <v-col cols="12" lg="3" class=" d-flex" >

            <div class="bg-dark-5 px-5 py-4 rounded elevation-3">

              <v-btn
              variant="outlined"
              color="dark-2"
              class="ms-3"
              size="small"
              prepend-icon="mdi-calendar"
              >Select Period</v-btn
            >
            </div>
          </v-col>
        </v-row>
      </div>

      <div class="mt-7">
        <v-data-table :headers="headers" :items="items" class="elevation-2 bg-dark-5 text-dark-6 font-weight-bold pa-3 shadow-lg">
          <template v-slot:top> </template>
          <template v-slot:[`item.amount`]="{ item }">
            {{ item.amount ? getFormattedAmount(item.amount) : "0" }}
          </template>
          <template v-slot:[`item.token`]="{ item }">
            {{ item.token ? getFormattedAmount(item.token) : "0" }}
          </template>

          <template v-slot:[`item.index`]="{ index }">
            <div class="d-flex align-center">
              <span class="fs-6" v-if="index + 1 == 1">
                {{ index + 1 }}st
              </span>
              <span class="fs-6" v-if="index + 1  == 2">
                {{ index + 1 }}nd
              </span>
              <span class="fs-6" v-if="index + 1 == 3">
                {{ index + 1 }}rd
              </span>
              <!-- <span class="fs-6" v-else>
                {{ index != 0 || index != 1 || index != 2 ? index + 1 : "0"  }}
              </span> -->
             
              <v-img
                v-if="index === 0"
                src="../../assets/1st-Position.svg"
                alt="Gold Medal"
                max-height="50"
                max-width="50"
              />
              <v-img
                v-if="index === 1"
                src="../../assets/2nd-Position.svg"
                alt="Silver Medal"
                max-height="50"
                max-width="50"
              />
              <v-img
                v-if="index === 2"
                src="../../assets/3rd-Position.svg"
                alt="Bronze Medal"
                max-height="50"
                max-width="50"
              />
            </div>
          </template>

          <template v-slot:[`item.username`]="{ item }">
            <div class="d-flex align-center my-4">
              <v-img
                src="../../assets/bolajiAmusan.svg"
                max-width="50"
                max-height="50"
              ></v-img>
              <span class="ms-3">{{ item.fullname }}</span>
            </div>
          </template>
          <template v-slot:bottom>
            <div class="text-center pt-2">
              <v-pagination
                v-model="leaderBoardMetaData.currentPage"
                :length="leaderBoardMetaData.lastPage"
                @click.prevent="
                  getAllLeaderboardInfo(leaderBoardMetaData.currentPage)
                "
              ></v-pagination>
            </div>
          </template>
        </v-data-table>

        <p class="text-end mt-3">
          <span class="mt-3"
            >Showing {{ leaderBoardMetaData.from }} -
            {{ leaderBoardMetaData.to }} of {{ leaderBoardMetaData.total }}
          </span>
        </p>
      </div>
    </v-container>
  </AdminLayout>
</template>
<script setup>
import { ref, onMounted, reactive } from "vue";
import AdminLayout from "../../components/AdminLayout.vue";
import DataTable from "../../components/DataTable.vue"

import axiosInstance from "../../plugins/axios";

const items = ref([]);
const overlay = ref(true);

const leaderBoardMetaData = reactive({
  currentPage: "",
  from: "",
  to: "",
  perPage: "",
  total: "",
  lastPage: "",
})


const headers = ref([
  // { text: '#', value: 'index', sortable: false },
  {
    title: "Current Position",
    align: "start",

    key: "index",
  },
  { title: "Player", align: "start", key: "username" },
  { title: "Games Played", align: "start", key: "" },
  { title: "Coins", align: "start", key: "coin" },
  { title: "Amount of Token", align: "start", key: "token" },
]);

function getAllLeaderboardInfo(page = 1) {
      overlay.value = true

  axiosInstance
    .get(`/admin/leader-boards?page=${page}`)
    .then((response) => {
      items.value = response.data.data;


      leaderBoardMetaData.currentPage = response.data.meta.current_page
    // console.log(leaderBoardMetaData.currentPage)
    leaderBoardMetaData.perPage = response.data.meta.per_page
    leaderBoardMetaData.from = response.data.meta.from
    leaderBoardMetaData.to = response.data.meta.to
    leaderBoardMetaData.total = response.data.meta.total
    leaderBoardMetaData.lastPage = response.data.meta.last_page
      overlay.value = false

      // items.value.reverse();
      // console.log(items.value);
    })
    .catch((error) => {
      console.log(error);
    });
}

function getFormattedAmount(amount) {
  return new Intl.NumberFormat("en-US").format(amount);
}

onMounted(() => {
  getAllLeaderboardInfo();
});
</script>
<style scoped>
* {
  /* outline: 1px solid green; */
  font-family: "Nunito", sans-serif;
}

.fs-6 {
  font-size: 1rem;
}

.row-scroller {
  position: relative;
}

.row-scroller .row {
  display: flex;
  /* flex-wrap: wrap; */
  overflow-x: auto;
  scroll-behavior: smooth;
}
</style>