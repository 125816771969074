<template>
  <Bar :chart-options="chartOptions" :chart-data="chartData" />
</template>

<script setup>
import { Bar } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";
// import { ref } from "vue";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);

defineProps({
  chartData: {
    type: Object,

    default: () => ({}),
  },
  chartOptions: {
    type: Object,
    default: () => {},
  },
});
// const chartOptions = ref({});
// const chartData = ref({});

</script>