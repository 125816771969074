<template>
  <AdminLayout page="">
    <v-container>
      <v-row>
        <v-col cols="12" class="d-flex justify-end">
          <div class="d-flex">
            <v-btn class="elevation-0" color="gray">
              Reject Request
              <v-dialog
                v-model="dialog"
                activator="parent"
                width="600"
                opacity="-0.5"
                transition="dialog-top-transition"
              >
                <v-card class="pa-5 bg-dark elevation-3">
                  <div class="d-flex align-center justify-space-between">
                    <div>
                      <h3 class="fs-5">Reject Request</h3>
                      <p>Fund User's Wallet</p>
                    </div>
                    <div>
                      <!-- <v-img src="../../assets/close-btn.png" width="40" height="40"></v-img> -->
                      <v-btn icon="mdi-close" @click="dialog = false"></v-btn>
                    </div>
                  </div>
                  <v-form class="mt-5">
                    <v-row>
                      <v-col cols="12">
                        <p class="mb-3">Select User</p>
                        <v-autocomplete
                          variant="solo"
                          placeholder="Enter User ID, name to search for user"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12">
                        <v-btn type="submit" color="primary" block size="large"
                          >Deactivate</v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card>
              </v-dialog>
            </v-btn>
            <v-btn class="elevation-0 ms-1 ms-lg-3" color="primary">
              Approve Request
              <v-dialog
                v-model="dialog2"
                activator="parent"
                width="600"
                opacity="-0.5"
                transition="dialog-top-transition"
              >
                <v-card class="pa-5 bg-dark elevation-3">
                  <div class="d-flex align-center justify-space-between">
                    <div>
                      <h3 class="fs-5">Approve Request</h3>
                      <p>Approve request for User</p>
                    </div>
                    <div>
                      <!-- <v-img src="../../assets/close-btn.png" width="40" height="40"></v-img> -->
                      <v-btn icon="mdi-close" @click="dialog2 = false"></v-btn>
                    </div>
                  </div>
                  <v-form class="mt-5">
                    <v-row>
                      <v-col cols="12">
                        <p class="mb-3">Token Amount</p>
                        <v-text-field
                          variant="solo"
                          placeholder="Enter token amount"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-btn type="submit" color="primary" block size="large"
                          >Fund Wallet</v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card>
              </v-dialog>
            </v-btn>
          </div>
        </v-col>
        <v-col cols="12" lg="7" class="mt-3">
          <v-card class="pa-4 d-flex">
            <v-row>
              <v-col cols="12" lg="4">
                <div>
                  <v-img
                    src="../../assets/single-user.svg"
                    width="200"
                    height="200"
                  ></v-img>
                </div>
              </v-col>
              <v-col cols="8">
                <div class="mt-7">
                  <h3 class="fs-5">John Awoyokun</h3>
                  <p class="text-dark-2">@TheWarrior123</p>

                  <v-row class="mt-1">
                    <v-col cols="12" lg="5">
                      <div class="d-flex mt-7">
                        <div>
                          <v-img
                            src="../../assets/call.svg"
                            width="20"
                            height="30"
                          ></v-img>
                        </div>
                        <span class="text-dark-2 fs-xs"> +234567567547 </span>
                      </div>
                    </v-col>
                    <v-col cols="12" lg="4">
                      <div class="d-flex mt-0 mt-lg-6">
                        <div class="">
                          <v-img
                            src="../../assets/directnotification.svg"
                            width="20"
                            height="30"
                          ></v-img>
                        </div>
                        <span class="text-dark-2 ms-1 fs-xs mt-1"
                          >johnawokoyun@gmail.com</span
                        >
                      </div>
                    </v-col>
                  </v-row>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="12" lg="5" class="mt-5 border border-dark-2">
          <v-row class="mt-4">
            <v-col
              cols="4"
              class="border border-dark-2 border-t-0 border-e-0 border-s-0"
            >
              <h3 class="text-dark-2">Total Tokens</h3>
              <h2>10,000</h2>
            </v-col>
            <v-col
              cols="4"
              class="border border-dark-2 border-t-0 border-e-0 pa-4"
            >
              <h3 class="text-dark-2">Total Coins</h3>
              <h2>10,000</h2>
            </v-col>
            <v-col
              cols="4"
              class="border border-dark-2 border-t-0 border-e-0 pa-4"
            >
              <h3 class="text-dark-2">Games Played</h3>
              <h2>418</h2>
            </v-col>
            <v-col
              cols="4"
              class="border border-dark-2 border-t-0 border-e-0 border-b-0 border-s-0 pa-4"
            >
              <h3 class="text-dark-2">Games Won</h3>
              <h2>120</h2>
            </v-col>
            <v-col
              cols="4"
              class="border border-dark-2 border-t-0 border-e-0 border-b-0 pa-4"
            >
              <h3 class="text-dark-2">Games Lost</h3>
              <h2>208</h2>
            </v-col>
            <v-col
              cols="4"
              class="border border-dark-2 border-t-0 border-e-0 border-b-0 pa-4"
            >
              <h3 class="text-dark-2">Leaderboard Pos</h3>
              <h2>12th</h2>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row class="mt-5">
        <v-col cols="12">
          <p class="fs-5 font-weight-bold">Submitted Information</p>
        </v-col>

        <v-col cols="12" lg="6">
          <v-img src="../../assets/drivingLicense.svg"></v-img>
        </v-col>
        <v-col cols="12" lg="6">
          <v-row>
            <v-col cols="6">
              <h3>First Name</h3>
              <p class="text-dark-2">John</p>
            </v-col>
            <v-col cols="6">
              <h3>Last Name</h3>
              <p class="text-dark-2">Awoyokun</p>
            </v-col>
            <v-col cols="6">
              <h3>Middle Name</h3>
              <p class="text-dark-2">Temidayo</p>
            </v-col>
            <v-col cols="6">
              <h3>Phone Number</h3>
              <p class="text-dark-2">08043645665</p>
            </v-col>
            <v-col cols="12">
              <h3>House Address</h3>
              <p class="text-dark-2">
                81, Admiralty Road, Lekki Phase 1, Lagos
              </p>
            </v-col>
            <v-col cols="6">
              <h3>Bank</h3>
              <p class="text-dark-2">Guaranty Trust Bank</p>
            </v-col>
            <v-col cols="6">
              <h3>Account Number</h3>
              <p class="text-dark-2">0127192831</p>
            </v-col>
            <v-col cols="6">
              <h3>Account Name</h3>
              <p class="text-dark-2">John Temidayo Awoyokun</p>
            </v-col>
            <v-col cols="6">
              <h3>BVN</h3>
              <p class="text-dark-2">58193810391</p>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </AdminLayout>
</template>
<script setup>
import AdminLayout from "../../components/AdminLayout.vue";

import { ref } from "vue";

const dialog = ref(false);
const dialog2 = ref(false);
</script>
<style scoped>
* {
  /* outline: 1px solid green; */
  font-family: "Nunito", sans-serif;
}

.fs-5 {
  font-size: 2rem;
}
.fs-xs {
  font-size: 0.95rem;
}
</style>