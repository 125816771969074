// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Vuetify
import { createVuetify } from 'vuetify'


const myCustomLightTheme = {
  dark: true,
  colors: {
    'dark': '#0a0a0a',
    'dark-2': '#9c9c9c',
    'dark-3': '#0f0f0f',
    'dark-4': '#707070',
    'dark-5': '#0d0d0d',
    'dark-6': '#4d4d4d',
    'dark-7': '#212121',
    'gray': '#3b3b3b',
    'light-green': '#478f52',
    'light-gray': '#d9d9d9',
     primary: '#aa2f4b',
     'primary-2': '#8a2748',
    'darker': '#313131',
    'nav-drawer':'#191919',
    overlay: 'rgba(0,0,0,0.8)',
    pink: '#bf3865',
    red: '#88211e',
    green: '#068748',
    // ov: 'rgba(0,0,0,0.7)'
   
   
  },
}

export default createVuetify({
  theme: {
    defaultTheme: 'myCustomLightTheme',
    themes: {
      myCustomLightTheme,
    },
  },
})

// export default createVuetify({

// }
// theme: {
// defaultTheme: 'myCustomLightTheme',
// themes: {
//   myCustomLightTheme,
// },
// },
//   // https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
// )
