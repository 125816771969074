import { createRouter, createWebHistory } from 'vue-router'
import AdminLogin from '../views/admin/AdminLogin.vue'
import AdminDashboard from '../views/admin/AdminDashboard.vue'
import AdminTransaction from '../views/admin/AdminTransactions.vue'
import AdminUsers from '../views/admin/AdminUsers.vue'
import AdminBlog from '../views/admin/AdminBlog.vue'
import AdminViewSingleUser from '../views/admin/AdminViewSingleUser.vue'

import AdminKYCApprovals from '../views/admin/AdminKYCApprovals.vue'
import AdminViewSingleKYCApprovals from '../views/admin/AdminViewSingleKYCApprovals.vue'
import AdminProfileSettings from '../views/admin/AdminProfileSettings.vue'
import AdminProfile from '../views/admin/AdminViewProfilePage.vue'
import AdminLeaderboard from '../views/admin/AdminLeaderBoard.vue'
import AdminAnalytics from '../views/admin/AdminAnalytics.vue'
import AdminChat from '../views/admin/AdminChat.vue'
import AdminGames from '../views/admin/AdminGames.vue'

const routes = [
  {
    path: '/',
    name: 'login',
    component: AdminLogin,
    // meta: {
    //   auth: true
    // }
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: AdminDashboard,
    meta: {
      auth: true
    }
  },
  {
    path: '/transaction',
    name: 'Transaction',
    component: AdminTransaction,
    meta: {
      auth: true
    }
  },
  {
    path: '/users',
    name: 'Users',
    component: AdminUsers,
    meta: {
      auth: true
    }
  },
  {
    path: '/user/:user_id',
    name: 'AdminViewSingleUser',
    component: AdminViewSingleUser,
    meta: {
      auth: true
    }
  },
  {
    path: '/blogs',
    name: 'Blogs',
    component: AdminBlog,
    meta: {
      auth: true
    }
  },
  {
    path: '/kycApprovals',
    name: 'AdminKYCApprovals',
    component: AdminKYCApprovals,
    meta: {
      auth: true
    }
  },
  {
    path: '/single-kycApprovals',
    name: 'AdminViewSingleKYCApprovals',
    component: AdminViewSingleKYCApprovals,
    meta: {
      auth: true
    }
  },
  {
    path: '/profile-settings',
    name: 'AdminProfileSettings',
    component: AdminProfileSettings,
    meta: {
      auth: true
    }
  },
  {
    path: '/view-profile',
    name: 'AdminProfile',
    component: AdminProfile,
    meta: {
      auth: true
    }
  },
  {
    path: '/leaderboard',
    name: 'AdminLeaderboard',
    component: AdminLeaderboard,
    meta: {
      auth: true
    }
  },
  {
    path: '/analytics',
    name: 'AdminAnalytics',
    component: AdminAnalytics,
    meta: {
      auth: true
    }
  },
  {
    path: '/chat',
    name: 'AdminChat',
    component: AdminChat,
    meta: {
      auth: true
    }
  },
  {
    path: '/games',
    name: 'AdminGames',
    component: AdminGames,
    meta: {
      auth: true
    }
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// router.beforeEach((to, from, next) => {
//   const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
//   const token = localStorage.getItem('token');

//   if (requiresAuth && !token) {
//     next({ name: 'login', query: { redirect: to.path } , force: true});
//     console.log(123454)
//   } else {
//     next();
//   }
// });


// router.beforeEach((to, from, next) => {
//   if(to){
//     console.log(to);
//   }
//   if (to.name !== 'login' && !token && requiresAuth) next({ name: 'Login' })
//   else next()
// })

router.beforeEach(async (to, from) => {
  // const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const token = localStorage.getItem('token');
  if (

    !token  && to.name !== 'login'
  ) {
    // redirect the user to the login page
    return { name: 'login' }
  }
})

export default router
