<template>
  <AdminLayout page="Chat">
    <v-snackbar
      v-model="snackbar.status"
      :color="snackbar.color"
      :timeout="snackbar.timeout"
      location="top"
      >{{ snackbar.msg }}</v-snackbar
    >
    <v-container v-if="overlay">
      <v-row>
        <v-col cols="6">
          <v-skeleton-loader type="card"></v-skeleton-loader>
        </v-col>
        <v-col cols="6">
          <v-skeleton-loader type="card"></v-skeleton-loader>
        </v-col>
        
      </v-row>
    </v-container>
    <v-container v-else>
      <v-row class="mb-6">
        <v-col cols="12" lg="9">
          <p class="mt-2 text-dark-2">
            Quick Summary of all the information on this platform
          </p>
        </v-col>
        
      </v-row>

      <v-row>
        <v-col cols="12" lg="5" class="">
          <div class="pa-4 bg-dark-5 rounded">
            <div class="ps-sticky">
              <p class="text-uppercase text-darker-2 mt-2">Today</p>

              <v-table density="comfortable" class="bg-dark-5 pa-2">
                <tbody>
                  <tr
                    :key="index"
                    v-for="(item, index) in items"
                    @click="showMoreDetail(item)"
                    class="cursor-pointer"
                  >
                    <div
                      class="mt-2 d-flex justify-space-between my-5 pa-3 rounded"
                    >
                      <div>
                        <div class="d-flex">
                          <v-img
                            src="../../assets/bolajiAmusan.svg"
                            width="60"
                            height="50"
                          ></v-img>
                          <div class="ms-2">
                            <p class="fs-sm">
                              @{{
                                item.data.username
                                  ? item.data.username
                                  : "Nameless"
                              }}
                            </p>
                            <p class="fs-xs mt-2 text-capitalize text-dark-2">
                              {{
                                item.data.message
                                  ? item.data.message
                                  : "No Message"
                              }}
                            </p>
                          </div>
                        </div>
                      </div>

                      <div>
                        <p class="text-primary">
                          {{
                            item.created_at
                              ? formatTime(item.created_at)
                              : "No set Time"
                          }}
                        </p>
                      </div>
                    </div>
                  </tr>
                </tbody>
              </v-table>
            </div>

            <!-- <div>
              <p class="text-uppercase text-darker-2 mt-6">Yesterday</p>
  
              <div class="mt-2 d-flex justify-space-between">
                <div>
                  <v-img
                    src="../../assets/chat3.png"
                    width="60"
                    height="50"
                  ></v-img>
                </div>
                <div>
                  <p class="fs-sm text-darker">@TheWarrior123</p>
                  <p class="fs-xs mt-2 text-darker">
                    I have not been able to withdraw my...
                  </p>
                </div>
                <div>
                  <p class="text-darker">6:00pm</p>
                </div>
              </div>
              <div class="mt-2 d-flex justify-space-between mt-3">
                <div>
                  <v-img
                    src="../../assets/chat4.png"
                    width="60"
                    height="50"
                  ></v-img>
                </div>
                <div>
                  <p class="fs-sm text-darker">@TheWarrior123</p>
                  <p class="fs-xs text-darker mt-2">
                    I have not been able to withdraw my...
                  </p>
                </div>
                <div>
                  <p class="text-darker">6:00pm</p>
                </div>
              </div>
            </div> -->
            <!-- <div>
              <p class="text-uppercase text-darker-2 mt-6">Wednesday</p>
  
              <div class="mt-2 d-flex justify-space-between">
                <div>
                  <v-img
                    src="../../assets/chat5.png"
                    width="60"
                    height="50"
                  ></v-img>
                </div>
                <div>
                  <p class="fs-sm text-darker">@TheWarrior123</p>
                  <p class="fs-xs mt-2 text-darker">
                    I have not been able to withdraw my...
                  </p>
                </div>
                <div>
                  <p class="text-darker">6:00pm</p>
                </div>
              </div>
              <div class="mt-2 d-flex justify-space-between mt-3">
                <div>
                  <v-img
                    src="../../assets/chat6.png"
                    width="60"
                    height="50"
                  ></v-img>
                </div>
                <div>
                  <p class="fs-sm text-darker">@TheWarrior123</p>
                  <p class="fs-xs text-darker mt-2">
                    I have not been able to withdraw my...
                  </p>
                </div>
                <div>
                  <p class="text-darker">6:00pm</p>
                </div>
              </div>
            </div> -->
          </div>
        </v-col>
        <v-col cols="12" lg="7">
          <div class="bg-dark-5 rounded ps-sticky" style="min-height: 100vh">
            <div class="d-flex border border-t-0 border-s-0 border-e-0 py-3">
              <div class="ps-3">
                <v-img
                  src="../../assets/bolajiAmusan.svg"
                  width="100"
                  height="100"
                ></v-img>
              </div>
              <div class="ms-3">
                <p class="mt-3 mb-3">
                  <span v-if="selectedItem != []" class="fs-3 text-capitalize">
                    {{ selectedItem ? selectedItemUsername : "Nameless" }}
                  </span>
                  <span v-else class="fs-3"> Nobody </span>
                </p>
                <span class="text-primary">Active</span>
              </div>
            </div>
            <div>
              <div class="d-flex py-5">
                <div class="ps-5">
                  <v-img
                    src="../../assets/bolajiAmusan.svg"
                    width="50"
                    height="50"
                  ></v-img>
                </div>
                <div
                  class="ms-3 bg-dark-7 pa-4 w-80 rounded"
                  style="min-height: 50vh"
                >
                  <span v-if="selectedItem" class="fs-3 text-capitalize">{{
                    selectedItem ? selectedMessage : ""
                  }}</span>
                </div>
              </div>
              <p v-if="selectedItem" class="text-dark-2 ms-14">
                {{ selectedItem ? selectedItemTime : "No Set Time" }}
              </p>
              <div class="bg-dark-5 mt-7" style="min-height: 50vh">
               <v-form ref="formRef">
                <v-row class="d-flex align-center">
                  <v-col cols="12">

                    <v-textarea placeholder="Send Message..." variant="solo" class="mt-4 ms-6" v-model="sendMessageForm.message"></v-textarea>
                  </v-col>
                  <v-col cols="12" class="d-flex justify-end">
                    <v-btn @click="sendMessage(selectedItem.id)" color="primary" size="x-large" class="mb-1" append-icon="mdi-send">Send</v-btn>
                  </v-col>
                </v-row>
               </v-form>
              </div>
            </div>

          </div>
        </v-col>
      </v-row>
     
    </v-container>
  </AdminLayout>
</template>

<script setup>
import AdminLayout from "../../components/AdminLayout.vue"

import axiosInstance from "@/plugins/axios"

import { ref, onMounted, reactive } from "vue"

let date = ref("")
const items = ref([])
const dialog2 = ref(false)
const overlay = ref(true)

let selectedItem = ref([])
let selectedMessage = ref("")
let selectedItemUsername = ref("")
let selectedItemTime = ref("")
const formRef = ref(null)


const loading = ref(false)

date.value = new Date().getFullYear()

const snackbar = reactive({
  status: false,
  msg: "",
  color: "",
  timeout: 5000,
})

const sendMessageForm = reactive({
  message: "",
})

function sendMessage(id) {
  loading.value = true
  axiosInstance
    .put(
      `/admin/notifications/${id}`,
      sendMessageForm
    )
    .then((response) => {
      console.log(response)
      loading.value = false
      snackbar.status = true
      snackbar.color = "green"
      snackbar.msg = response.data.message
      // dialog2.value = false
      formRef.value.reset()

    })
    .catch((error) => {
      console.log(error.response)
      loading.value = false
      formRef.value.reset()
       
      snackbar.status = true
      snackbar.color = "red"
      snackbar.msg = error.response.data.message
      // dialog2.value = false

    })
}

function getAllChatInfo() {
  axiosInstance
    .get(`/admin/notifications`)
    .then((response) => {
      items.value = response.data
      overlay.value = false
      // items.value.reverse();
      // console.log(items.value)
    })
    .catch((error) => {
      console.log(error)
    })
}

function showMoreDetail(item) {
  selectedItem.value = item
  console.log(selectedItem.value)

  selectedItemUsername = selectedItem.value.data.username
  selectedMessage = selectedItem.value.data.message
  selectedItemTime = formatTime(selectedItem.value.created_at)
}

function formatTime(isoString) {
  const date = new Date(isoString)
  let hours = date.getUTCHours()
  const minutes = date.getUTCMinutes()
  const ampm = hours >= 12 ? "pm" : "am"

  hours = hours % 12
  hours = hours ? hours : 12 // the hour '0' should be '12'
  const minutesStr = minutes < 10 ? "0" + minutes : minutes
  const strTime = hours + ":" + minutesStr + ampm
  return strTime
}

onMounted(() => {
  getAllChatInfo()
})
</script>

<style scoped>
* {
  /* outline: 1px solid green; */
  font-family: "Nunito", sans-serif;
}

.fs-3 {
  font-size: 1.5rem;
}
.fs-4 {
  font-size: 1.8rem;
}
.ms-14 {
  padding-left: 1.7rem;
}

.ps-sticky {
  position: sticky !important;
  top: 0.8rem !important;
}

.cursor-pointer {
  cursor: pointer;
  overflow-y: scroll;
}

.w-80 {
  width: 85%;
}
</style>
