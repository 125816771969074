<template>
  <div>
    <v-navigation-drawer
      v-model="drawer"
      :width="240"
      elevation="3"
      class="bg-nav-drawer drawer"
    >
      <a href="#">
        <v-img
          src="../assets/logo-variant-light.png"
          max-height="70"
          max-width="70"
          class="mt-5 ms-3"
        ></v-img>
      </a>
      <v-list>
        <!-- <v-list-item link class="mt-17">
          <template v-slot:prepend>
            <v-icon icon="mdi-view-dashboard-outline" class="me-0"></v-icon>
          </template>

          <v-list-item-title class="ms-0">Dashboard</v-list-item-title>
        </v-list-item> -->
        <v-list-item link value="Dashboard" class="mt-17" :to="{name: 'Dashboard'}">
          <v-list-item-title class="ms-0">
            <v-img
              src="../assets/dashboard-logo.svg"
              width="40"
              height="30"
            ></v-img>
            <span class="position-relative" style="bottom: 1.7rem; left: 3.5rem"
              >Dashboard</span
            >
          </v-list-item-title>
        </v-list-item>
        <v-list-item link value="Transaction" :to="{name: 'Transaction'}">
          <div class="position-relative">

            <v-list-item-title class="ms-0 mt-3">
              <v-img
                src="../assets/transaction.svg"
                width="40"
                height="30"
              ></v-img>
              <span class="position-relative" style="bottom: 1.7rem; left: 3.5rem"
                >Transaction</span
              >
            </v-list-item-title>
            <v-chip  class="bg-primary pa-2 rounded-pill fs-xs shadow-sm position-absolute" style="top:0; right:0;">{{ items2 }}</v-chip>
          </div>
        </v-list-item>
        <v-list-item link value="Analytics" :to="{name: 'AdminAnalytics'}">
          <div class="position-relative">

            <v-list-item-title class="ms-0">
              <v-img src="../assets/analytics.svg" width="40" height="30"></v-img>
              <span class="position-relative" style="bottom: 1.7rem; left: 3.5rem"
                >Analytics</span
              >
            </v-list-item-title>
            <!-- <v-chip  class="bg-primary pa-2 rounded-pill fs-xs shadow-sm position-absolute" style="top:0; right:0;">200</v-chip> -->

          </div>
        </v-list-item>
        <v-list-item link value="Users" :to="{name: 'Users'}">
          <div class="position-relative">

            <v-list-item-title class="ms-0">
              <v-img
                src="../assets/profile2user.svg"
                width="40"
                height="30"
              ></v-img>
              <span class="position-relative" style="bottom: 1.7rem; left: 3.5rem"
                >Users</span
              >
            </v-list-item-title>
            <!-- <v-chip  class="bg-primary pa-2 rounded-pill fs-xs shadow-sm position-absolute" style="top:0; right:0;">200</v-chip> -->

          </div>
        </v-list-item>
        <!-- <v-list-item link value="KYC Approvals" :to="{name: 'AdminKYCApprovals'}">
          <v-list-item-title class="ms-0">
            <v-img src="../assets/approvals.svg" width="40" height="30"></v-img>
            <span class="position-relative" style="bottom: 1.7rem; left: 5rem"
              >Approvals</span
            >
          </v-list-item-title>
        </v-list-item> -->
        <v-list-item link value="Games" :to="{name: 'AdminGames'}">
          <div class="position-relative">

            <v-list-item-title class="ms-0">
              <v-img src="../assets/games.svg" width="40" height="30"></v-img>
              <span class="position-relative" style="bottom: 1.7rem; left: 3.5rem"
                >Games</span
              >
            </v-list-item-title>
            <v-chip class="bg-primary pa-2 rounded-pill fs-xs shadow-sm position-absolute" style="top:0; right:0;">{{ gameData }}</v-chip>

          </div>
        </v-list-item>
        <v-list-item link value="Leaderboard" :to="{name: 'AdminLeaderboard'}">
           <div class="position-relative">

             <v-list-item-title class="ms-0">
               <v-img
                 src="../assets/leaderboard.svg"
                 width="40"
                 height="30"
               ></v-img>
               <span class="position-relative" style="bottom: 1.7rem; left: 3.5rem"
                 >Leaderboard</span
               >
             </v-list-item-title>
            <v-chip class="bg-primary pa-2 rounded-pill fs-xs shadow-sm position-absolute" style="top:0; right:0;">{{leaderboardData}}</v-chip>

           </div>
        </v-list-item>
        <v-list-item link value="Chat" :to="{name: 'AdminChat'}">
          <div class="position-relative">

            <v-list-item-title class="ms-0">
              <v-img src="../assets/chat.svg" width="40" height="30"></v-img>
              <span class="position-relative" style="bottom: 1.7rem; left: 3.5rem"
                >Chat</span
              >
            </v-list-item-title>
            <v-chip class="bg-primary pa-2 rounded-pill fs-xs shadow-sm position-absolute" style="top:0; right:0;">{{ chatsData }}</v-chip>

          </div>
        </v-list-item>
        <v-list-item link value="Blog & Media" :to="{name: 'Blogs'}">
          <v-list-item-title class="ms-0">
            <v-img
              src="../assets/blogandMedia.svg"
              width="40"
              height="30"
            ></v-img>
            <span class="position-relative" style="bottom: 1.7rem; left: 3.5rem"
              >Blog & Media</span
            >
          </v-list-item-title>
        </v-list-item>
        <v-list-item link value="Profile & Settings" :to="{name: 'AdminProfileSettings'}">
          <v-list-item-title class="ms-0">
            <v-img src="../assets/setting.svg" width="40" height="30"></v-img>
            <span class="position-relative" style="bottom: 1.7rem; left: 3.5rem"
              >Profile & Settings</span
            >
          </v-list-item-title>
        </v-list-item>
      </v-list>

      <v-list class="mt-10">
        <v-list-item link value="Profiles" :to="{name: 'AdminProfile'}">
          <v-list-item-title class="ms-0">
            <v-img
              src="../assets/bolajiAmusan.svg"
              width="40"
              height="30"
            ></v-img>
            <span class="position-relative" style="bottom: 1.8rem; left: 3.5rem"
              >Bolaji Amusan</span
            >
          </v-list-item-title>
          <span class="position-relative" style="bottom: 1.7rem; left: 3.5rem"
            >Admin</span
          >
        </v-list-item>

        <v-list-item link value="Logout" @click="logOut">
          <v-list-item-title class="ms-0">
            <v-img src="../assets/logout.svg" width="40" height="30"></v-img>
            <span class="position-relative" style="bottom: 1.7rem; left: 3.5rem"
              >Logout</span
            >
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <!-- <v-app-bar class="elevation-0" scroll-behavior="elevate">
      <template v-slot:prepend>
        <v-app-bar-nav-icon
        @click="drawer = !drawer"
          @click.stop="mini = !mini"
        ></v-app-bar-nav-icon>
      </template>
      
      
    </v-app-bar> -->
    <div class="bg-dark">
      <v-btn @click="drawer = !drawer" @click.stop="mini = !mini" icon="mdi-menu" class="elevation-0" variant="plain"></v-btn>
    </div>
    <v-toolbar-title class="font-weight-bold fs-7 ps-4 bg-dark">{{ page }}</v-toolbar-title>
    <v-main class="custom-main bg-dark min-h-100">
      <slot />
    </v-main>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import axiosInstance from "../plugins/axios"


const router = useRouter();

const drawer = ref(null);
const mini = ref(true);

const items2 = ref(null)
const userData = ref(null)
const chatsData = ref(null)
const gameData = ref(null)
const leaderboardData = ref(null)



async function initData() {
  try {
   
    const transactionData = await axiosInstance.get(
      `/admin/dashboard/recent-events?type=transactions`
    )
    const gamesData = await axiosInstance.get(`/admin/games`)
    const leaderboardsData = await axiosInstance.get(`/admin/leader-boards`)

    const usersData = await axiosInstance.get(`/admin/users`)
    const chatData = await axiosInstance.get(`/admin/notifications`)

    items2.value = transactionData.data.data.length

    // console.log(items2.value)
    userData.value = usersData.data.data
    // console.log(userData.value)
    chatsData.value = chatData.data.length

    gameData.value = gamesData.data.meta.total
    leaderboardData.value = leaderboardsData.data.meta.total
    // console.log(gameData.value)

  } catch (e) {
    console.log(e)
  }
}


function logOut(){
  localStorage.removeItem("token");
   router.push({name: 'login'})
}

defineProps({
  page: {
    type: String,
    default: "Page",
  },
});


onMounted(() => {
  initData()
})
</script>
<style scoped>
* {
  /* outline: 1px solid blue; */
  font-family: "Nunito", sans-serif;
}

.min-h-100{
  min-height:100vh;
}
.custom-main {
  --v-layout-left: 0px !important;
}
.mt-17 {
  margin-top: 6rem;
}

.v-list-item--active {
  border-right: 2px solid #ff4c29; /* Adjust the color as needed */
}

.v-list-item--active .text-primary {
  color: #191919 !important;
}

.drawer {
  overflow-y: hidden !important;
}

::v-deep .v-navigation-drawer__content {
  overflow: auto; /* Ensure the content can be scrolled */
}

::v-deep ::-webkit-scrollbar {
  width:  0; /* Hide scrollbar for Chrome, Safari and Opera */
}

::v-deep ::-webkit-scrollbar-thumb {
  background: transparent; /* Hide scrollbar thumb for Chrome, Safari and Opera */
}

::v-deep .v-navigation-drawer__content::-webkit-scrollbar {
  width:  0; /* Hide scrollbar for Edge and IE */
}




.fs-7{
  font-size: 2rem;
}

.fs-xs{
  font-size: 0.75rem;
}
</style>