~<template>
  <AdminLayout page="Analytics">
    <div v-if="overlay">
      <DataTable />
    </div>
    <v-container v-else>
      <p class="mt-0 text-dark-2">
        Quick Summary of all the information on the platform
      </p>

      <v-card class="mt-6 bg-dark-5">
        <v-tabs v-model="tab" align-tabs="center" color="primary">
          <v-tab value="one"
            ><v-btn variant="outlined" class="ma-3"
              >User Analytics</v-btn
            ></v-tab
          >
          <v-tab value="two">
            <v-btn variant="outlined" class="ma-3">Game Analytics</v-btn></v-tab
          >
          <v-tab value="three"
            ><v-btn variant="outlined" class="ma-3"
              >Revenue Analytics</v-btn
            ></v-tab
          >
          <v-tab value="four"
            ><v-btn variant="outlined" class="ma-3"
              >Transaction Analytics</v-btn
            ></v-tab
          >
        </v-tabs>

        <v-card-text>
          <v-window v-model="tab">
            <v-window-item value="one">
              <div class="mt-6">
                <v-row class="d-flex justify-end">
                  <v-col cols="6" class="">
                    <p class="mb-1">Start Month</p>
                    <v-text-field
                      variant="outlined"
                      v-model="getUserRange.startMonth"
                      type="date"
                      class="me-3 rounded w-75"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" class="">
                    <p class="mb-1">End Month</p>

                    <v-text-field
                      variant="outlined"
                      v-model="getUserRange.endMonth"
                      type="date"
                      @input="getUserAnalytics"
                      class="rounded w-75"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <apexchart
                  type="bar"
                  ref="userRef"
                  :options="userChartOptions"
                  :series="userSeries"
                ></apexchart>
              </div>
            </v-window-item>

            <v-window-item value="two">
              <div class="mt-6">
                <v-row>
                  <v-col cols="6">
                    <p class="mb-1">Start Month</p>
                    <v-text-field
                      variant="outlined"
                      v-model="getGameStakeRange.startMonth"
                      type="date"
                      @input="getGameStakeAnalytics"
                      class="me-3 rounded"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <p class="mb-1">End Month</p>

                    <v-text-field
                      variant="outlined"
                      v-model="getGameStakeRange.endMonth"
                      type="date"
                      @input="getGameStakeAnalytics"
                      class="rounded"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <apexchart
                  type="bar"
                  ref="gameRef"
                  :options="gameChartOptions"
                  :series="gameSeries"
                ></apexchart>

                <v-row class="mt-4">
                  <v-col cols="12">
                    <p class="fs-3 text-center mt-6">Token Vs Coins</p>
                  </v-col>
                  <v-col cols="6">
                    <p class="mb-1">Start Month</p>
                    <v-text-field
                      variant="outlined"
                      type="date"
                      class="me-3 rounded"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <p class="mb-1">End Month</p>

                    <v-text-field
                      variant="outlined"
                      type="date"
                      class="rounded"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <BarChart :data="BarChartData3" />
              </div>
            </v-window-item>

            <v-window-item value="three">
              <div class="mt-6">
                <v-row class="mb-5 d-flex justify-end">
                  <v-col cols="6">
                    <p class="mb-1">Year</p>

                    <v-text-field
                      variant="outlined"
                      v-model="getRevenueRange.year"
                      type="text"
                      @keyup.enter="getRevenueAnalyticsData"
                      class="rounded"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <apexchart
                  type="bar"
                  ref="revenueRef"
                  :options="revenueChartOptions"
                  :series="revenueSeries"
                ></apexchart>
              </div>
            </v-window-item>
            <v-window-item value="four">
              <div class="mt-6">
                <v-row class="mb-5 d-flex justify-end">
                  <v-col cols="6">
                    <p class="mb-1">Year</p>

                    <v-text-field
                      variant="outlined"
                      v-model="getTransactionRange.year"
                      @keyup.enter="getTransactionAnalyticsData"
                      type="text"
                      class="rounded"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <apexchart
                  type="bar"
                  ref="transactionRef"
                  :options="transactionChartOptions"
                  :series="transactionSeries"
                ></apexchart>
              </div>
            </v-window-item>
          </v-window>
        </v-card-text>
      </v-card>
    </v-container>
  </AdminLayout>
</template>
<script setup>
import AdminLayout from "../../components/AdminLayout.vue"
import BarChart from "../../components/charts/BarChart.vue"
import DataTable from "../../components/DataTable.vue"

import axiosInstance from "../../plugins/axios"

import { ref, reactive, onMounted, watch } from "vue"

const tab = ref(null)

const chartInstanceOne = ref(null)

const overlay = ref(false)

const transactionChartOptions = reactive({
  chart: {
    type: "bar",
    height: 350,
  },
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: "55%",
      endingShape: "rounded",
    },
  },
  dataLabels: {
    enabled: false,
    style: {
      color: ["#478f52", "#aa2f4b"],
    },
  },
  colors: ["#aa2f4b", "#478f52"],
  legend: {
    position: "top",
  },

  xaxis: {
    categories: [],
     title: {
      text: "MONTHS",
    },
  },
   yaxis: {
    title: {
      text: "AMOUNT STAKED",
    },
  },
  grid: { show: false },

  tooltip: {
    theme: false,
    y: {
      formatter: function (val) {
        return "₦ " + val
      },
    },
    marker: {
      show: true,
    },
  },
})
const revenueChartOptions = reactive({
  chart: {
    type: "bar",
    height: 350,
  },
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: "25%",
      endingShape: "rounded",
    },
  },
  dataLabels: {
    enabled: false,
    style: {
      color: ["#478f52"],
    },
  },
  colors: ["#478f52"],
  legend: {
    position: "top",
  },

  xaxis: {
    categories: [],
     title: {
      text: "MONTHS",
    },
  },
   yaxis: {
    title: {
      text: "REVENUE GENERATED",
    },
  },
  grid: { show: false },

  tooltip: {
    theme: false,
    y: {
      formatter: function (val) {
        return "₦ " + val
      },
    },
    marker: {
      show: true,
    },
  },
})

const revenueSeries = ref([
  {
    name: "Revenue",
    data: [],
  },
])

// watch(
//   () => transactionChartOptions.xaxis.categories,
//   (newCategories, oldCategories) => {
//     transactionChartOptions.xaxis.categories = newCategories
//     console.log(transactionChartOptions.xaxis.categories)
//   },
//   { deep: true }
// )

const transactionSeries = ref([
  {
    name: "Withdrawal",
    data: [],
  },
  {
    name: "Deposit",
    data: [],
  },
])

const gameChartOptions = reactive({
  chart: {
    type: "bar",
    height: 350,
  },
  plotOptions: {
    bar: {
      horizontal: true,
      columnWidth: "25%",
      endingShape: "rounded",
    },
  },
  dataLabels: {
    enabled: true,
    style: {
      color: ["#478f52"],
    },
  },
  colors: ["#478f52"],
  legend: {
    position: "right",
  },

  xaxis: {
    categories: [],
    title: {
      text: "NO OF GAMES PLAYED",
    },
  },
  yaxis: {
    title: {
      text: "STAKE AMOUNT",
    },
  },
  grid: { show: false },

  tooltip: {
    theme: false,
    // y: {
    //   formatter: function (value) {
    //     return value + " Game(s)"
    //   },
    // },
    marker: {
      show: true,
    },
  },
})
const gameSeries = ref([
  {
    data: [],
  },
])

const userChartOptions = reactive({
  chart: {
    type: "bar",
    height: 350,
  },
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: "25%",
      endingShape: "rounded",
    },
  },
  dataLabels: {
    enabled: false,
    style: {
      color: ["#478f52", "#aa2f4b", "#fff"],
    },
  },
  colors: ["#d9d9d9", "#478f52", "#aa2f4b"],
  legend: {
    position: "top",
  },

  xaxis: {
    categories: ["Data"],
  },
  grid: { show: false },

  tooltip: {
    theme: false,
    // y: {
    //   formatter: function (val) {
    //     return "₦ " + val
    //   },
    // },
    marker: {
      show: true,
    },
  },
})

const userSeries = ref([
  {
    name: "Inactive Users",
    data: [],
  },
  {
    name: "Active Users",
    data: [],
  },
  {
    name: "Disabled Users",
    data: [],
  },
])

const BarChartData3 = reactive({
  labels: ["January"],
  datasets: [
    {
      label: "Token",
      backgroundColor: "#aa2f4b",
      data: [60],
    },
    {
      label: "Coins",
      backgroundColor: "#478f52",
      data: [30],
    },
  ],
})

const getUserRange = reactive({
  startMonth: "",
  endMonth: "",
})

const getGameStakeRange = reactive({
  startMonth: "",
  endMonth: "",
})

const getRevenueRange = reactive({
  year: "",
})
const getTransactionRange = reactive({
  year: "",
})

const userRef = ref(null)
const transactionRef = ref(null)
const revenueRef = ref(null)
const gameRef = ref(null)

let transactionData = reactive({})
let revenueData = ref([])

function getUserAnalytics() {
  if (getUserRange.startMonth != "" && getUserRange.endMonth != "") {
    axiosInstance
      .get(
        `/admin/analytics?type=users&start_date=${getUserRange.startMonth}&end_date=${getUserRange.endMonth}`
      )
      .then((response) => {
        console.log(response.data)

        let activeUsers = response.data.active
        let inactiveUsers = response.data.in_active
        let disableUsers = response.data.disable

        userSeries.value = [
          {
            data: [inactiveUsers],
          },
          {
            data: [activeUsers],
          },
          {
            data: [disableUsers],
          },
        ]
      })
      .catch((error) => {
        console.log(error)
      })
  }
}

function getGameStakeAnalytics() {
  if (getGameStakeRange.startMonth != "" && getGameStakeRange.endMonth != "") {
    // clearUserAnalytics()

    axiosInstance
      .get(
        `/admin/analytics?type=games&start_date=${getGameStakeRange.startMonth}&end_date=${getGameStakeRange.endMonth}`
      )
      .then((response) => {
        //  console.log(response.data)

        let gameStakeData = response.data
        transformGameAnalytics(gameStakeData)
      })
      .catch((error) => {
        console.log(error)
      })
  }
}

function transformGameAnalytics(res) {
  const processedData = res
    .map((item) => [item.total_stake, Number(item.total)])
    .reduce((acc, [name, value]) => {
      acc[name] = (acc[name] || 0) + value
      console.log(acc)
      return acc
    }, {})

  const keys = Object.keys(processedData)
  const values = Object.values(processedData)

  gameRef.value.updateOptions({
    xaxis: {
      categories: keys,
    },
  })

  gameSeries.value = [
    {
      data: values,
    },
  ]
}

function getRevenueAnalyticsData() {
  axiosInstance
    .get(`/admin/analytics?type=revenues&year=${getRevenueRange.year}`)
    .then((response) => {
      revenueData.value = response.data
      // console.log(revenueData.value);
      transformRevenueData(revenueData.value)
    })
    .catch((error) => {
      console.log(error)
    })
}

function transformRevenueData(response) {
  const months = {}
  response.forEach((item) => {
    const [year, month, day] = item.date.split("-")
    const monthName = new Date(year, month - 1).toLocaleString("default", {
      month: "long",
    })
    if (!months[monthName]) {
      months[monthName] = parseFloat(item.total)
    } else {
      months[monthName] += parseFloat(item.total)
    }
  })

  const monthNames = Object.keys(months)
  const totals = Object.values(months)

  revenueRef.value.updateOptions({
    xaxis: {
      categories: monthNames,
    },
  })

  revenueSeries.value = [
    {
      name: "Withdrawals",
      data: totals,
    },
  ]
}

function getTransactionAnalyticsData() {
  axiosInstance
    .get(`/admin/analytics?type=transactions&year=${getTransactionRange.year}`)
    .then((response) => {
      transactionData = response.data
      transformTransactionData(transactionData)
    })
    .catch((error) => {
      console.log(error)
    })
}

function transformTransactionData(response) {
  const months = {}
  let monthNames = []
  ;["withdraw", "deposit"].forEach((type) => {
    response[type].forEach((item) => {
      const month = item.date.split("-")[1] // Extract month from date
      const year = new Date().getFullYear() // Get the current year dynamically
      const monthName = new Date(year, month - 1).toLocaleString("default", {
        month: "long",
      })
      if (!monthNames.includes(monthName)) {
        monthNames.push(monthName)
      }
      if (!months[monthName]) {
        months[monthName] = { withdraw: 0, deposit: 0 }
      }
      months[monthName][type] += parseFloat(item.total)
    })
  })

  transactionRef.value.updateOptions({
    xaxis: {
      categories: monthNames,
    },
  })

  // transactionChartOptions.xaxis.categories = monthNames
  // console.log(transactionChartOptions.xaxis.categories)
  transactionSeries.value = [
    {
      name: "Withdrawals",
      data: Object.values(months).map((month) => month.withdraw),
    },
    {
      name: "Deposits",
      data: Object.values(months).map((month) => month.deposit),
    },
  ]
}

onMounted(() => {
  // transformmData()
})
</script>
<style scoped>
* {
  /* outline: 1px solid green; */
  font-family: "Nunito", sans-serif;
}

.fs-3 {
  font-size: 1.3rem;
}

.row-scroller {
  position: relative;
}

.row-scroller .row {
  display: flex;
  /* flex-wrap: wrap; */
  overflow-x: auto;
  scroll-behavior: smooth;
}

.apexcharts-tooltip {
  background: orange !important;
  /* color: orange !important; */
}
</style>
