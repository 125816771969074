<template>
  <AdminLayout page="Games">
    <div v-if="overlay">
      <DataTable />
    </div>
    <v-container v-else>
      <v-row>
        <v-col cols="12">
          <div class="d-flex justify-space-between align-center">
            <p class="mt-0 text-dark-2">
              Quick Summary of all the information on this platform
            </p>
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="6" lg="3">
          <div class="bg-dark-3 rounded pa-4">
            <v-img
              src="../../assets/games-icon1.svg"
              width="40"
              class=""
            ></v-img>
            <h3 class="mt-4 font-weight-light">Total No of Games Played</h3>
            <h2 class="mt-3 fs-6">199</h2>
          </div>
        </v-col>
        <v-col cols="12" md="6" lg="3">
          <div class="bg-dark-3 rounded pa-4">
            <v-img
              src="../../assets/games-icon2.svg"
              width="40"
              class=""
            ></v-img>
            <h3 class="mt-4 font-weight-light">Total No of Active Games</h3>
            <h2 class="mt-3 fs-6">80</h2>
          </div>
        </v-col>
        <v-col cols="12" md="6" lg="3">
          <div class="bg-dark-3 rounded pa-4">
            <v-img
              src="../../assets/games-icon3.svg"
              width="40"
              class=""
            ></v-img>
            <h3 class="mt-4 font-weight-light">Total Games Concluded</h3>
            <h2 class="mt-3 fs-6">101</h2>
          </div>
        </v-col>
        <v-col cols="12" md="6" lg="3">
          <div class="bg-dark-3 rounded pa-4">
            <v-img
              src="../../assets/games-icon4.svg"
              width="40"
              class=""
            ></v-img>
            <h3 class="mt-4 font-weight-light">Total No of Games on App</h3>
            <h2 class="mt-3 fs-6">121</h2>
          </div>
        </v-col>
      </v-row>

      <div class="row-scroller mt-5">
        <v-row class="row rounded">
          <v-col cols="12" lg="9" class="">
            <div class="d-flex rounded justify-space-between align-center">
              <div class="d-flex align-center bg-dark-5 pa-4 rounded">
                <button
                  class="queryBtn"
                  
                  @click.prevent="filterByDate"
                >
                  All Games
                </button>

                <button
                  class="queryBtn"
                  
                  @click.prevent="filterByDate"
                >
                  Active Games
                </button>
                <button
                  class="queryBtn"
                  
                  @click.prevent="filterByDate"
                >
                  Concluded Games
                </button>
              </div>
            </div>
          </v-col>
          <v-col cols="12" lg="3" class="">
            <div class="d-flex rounded justify-space-between align-center">
              <div class="d-flex align-center bg-dark-5 pa-4 rounded">
                <button class="queryBtn d-flex align-center" @click="showDate">
                  <v-img
                    src="../../assets/calendar.svg"
                    width="30"
                    height="20"
                    class="me-1"
                  ></v-img
                  >Select Period
                </button>
              </div>
            </div>
          </v-col>
          <!-- <v-col cols="12" lg="2">
            <div class="d-flex align-center bg-dark-5">
              <v-btn
                variant="outlined"
                color="dark-2"
                class="ms-3"
                prepend-icon="mdi-calendar"
                >Select Period</v-btn
              >
            </div>
          </v-col> -->
        </v-row>
      </div>

      <div v-if="monthFields">
        <v-container>
          <v-row>
            <v-col cols="6">
              <p class="mb-1">Start Month</p>
              <v-text-field
                variant="outlined"
                v-model="startMonth"
                type="date"
                class="me-3 rounded"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <p class="mb-1">End Month</p>

              <v-text-field
                variant="outlined"
                v-model="endMonth"
                type="date"
                @input="filterByDate"
                class="rounded"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </div>

      <v-card class="scroller d-flex mt-7 elevation-1 p-5 bg-dark-3 ma-3">
        <v-table density="comfortable" class="bg-dark-3 ma-3">
          <tbody>
            <tr v-for="singleDetail in items" :key="singleDetail.id">
              <v-card
                class="bg-dark-3 pa-4 my-4 d-flex justify-space-between align-center"
              >
                <div class="d-flex align-center justify-space-between">
                  <div class="d-flex align-center ma-2">
                    <div>
                      <v-img
                        src="../../assets/clear.svg"
                        width="80"
                        height="80"
                        class="rounded-circle"
                      ></v-img>
                    </div>
                    <div class="ms-2">
                      <h3 class="fs-4 font-weight-bold">
                        {{ singleDetail.users[0].user.fullname }}
                      </h3>
                      <h4 class="text-dark-2 font-weight-light">
                        @{{ singleDetail.users[0].user.username }}
                      </h4>
                    </div>
                  </div>
                  <div class="ma-2">
                    <v-img
                      src="../../assets/VS.svg"
                      width="60"
                      height="60"
                    ></v-img>
                  </div>

                  <div class="d-flex align-center ma-2">
                    <div>
                      <v-img
                        src="../../assets/bolajiAmusan.svg"
                        width="80"
                        height="80"
                        class="rounded-circle"
                      ></v-img>
                    </div>
                    <div class="ms-2">
                      <h3 class="fs-4 font-weight-bold">
                        {{ singleDetail.users[1].user.fullname }}
                      </h3>
                      <h4 class="text-dark-2 font-weight-light">
                        @{{ singleDetail.users[1].user.username }}
                      </h4>
                    </div>
                  </div>
                </div>
                <div class="pa-4 bg-dark-1">
                  <h4 class="text-dark-2 text-center font-weight-light">
                    Game
                  </h4>
                  <h3
                    class="text-capitalize fs-3 text-center font-weight-bolder"
                  >
                    {{ singleDetail.type }}
                  </h3>
                </div>
                <div class="pa-4 bg-dark-1">
                  <h4 class="text-dark-2 text-center font-weight-light">
                    Total Stake
                  </h4>
                  <h3 class="text-capitalize fs-3 text-center font-weight-bold">
                    {{
                      singleDetail.total_stake
                        ? getFormattedAmount(singleDetail.total_stake)
                        : "0"
                    }}
                  </h3>
                </div>
                <div class="pa-4 bg-dark-1">
                  <h4
                    class="text-dark-2 text-center text-green font-weight-light"
                  >
                    Game Started
                  </h4>
                  <h3 class="text-capitalize fs-4 text-center text-green">
                    {{
                      singleDetail.started_at
                        ? getFormattedTime(singleDetail.started_at)
                        : "0"
                    }}
                  </h3>
                </div>
                <div class="pa-4 bg-dark-1">
                  <h4
                    class="text-dark-2 text-center text-red font-weight-light"
                  >
                    Game Ended
                  </h4>
                  <h3 class="text-capitalize fs-4 text-center text-red">
                    {{
                      singleDetail.ended_at
                        ? getFormattedTime(singleDetail.ended_at)
                        : "0"
                    }}
                  </h3>
                </div>
                <div class="pa-4 bg-dark-1">
                  <h4 class="text-dark-2 text-center font-weight-light">
                    Game Type
                  </h4>
                  <h3 class="text-capitalize fs-3 text-center">
                    {{ singleDetail.type }}
                  </h3>
                </div>
                <div class="pa-4 bg-dark-1">
                  <h4
                    class="text-dark-2 text-center text-pink font-weight-light"
                  >
                    Game Revenue
                  </h4>
                  <h3
                    class="text-capitalize fs-4 text-center text-pink font-weight-black"
                  >
                    <!-- {{ singleDetail.amount }} -->
                    ₦3,000
                  </h3>
                </div>
              </v-card>
            </tr>
          </tbody>
        </v-table>
      </v-card>

      <v-pagination v-model="gamesMetaData.currentPage" :length="gamesMetaData.lastPage" @click="getAllGamesInfo(gamesMetaData.currentPage)"></v-pagination>
      <p class="text-end mt-3">
        <span class="mt-3"
          >Showing {{ gamesMetaData.from }} -
          {{ gamesMetaData.to }} of {{ gamesMetaData.total }}
        </span>
      </p>
    </v-container>
  </AdminLayout>
</template>

<script setup>
import AdminLayout from "../../components/AdminLayout.vue"

import axiosInstance from "../../plugins/axios"
import DataTable from "../../components/DataTable.vue"

import { ref, onMounted, reactive } from "vue"

const items = ref([])

const monthFields = ref(false)

const startMonth = ref("")
const endMonth = ref("")

function showDate() {
  monthFields.value = true
}

const overlay = ref(true)

const gamesMetaData = reactive({
  currentPage: "",
  from: "",
  to: "",
  perPage: "",
  total: "",
  lastPage: "",
})

function getAllGamesInfo(page = 1) {
    overlay.value = true

  axiosInstance
    .get(`/admin/games?page=${page}`)
    .then((response) => {
      items.value = response.data.data

      gamesMetaData.currentPage = response.data.meta.current_page
      // console.log(gamesMetaData.currentPage)
      gamesMetaData.perPage = response.data.meta.per_page
      gamesMetaData.from = response.data.meta.from
      gamesMetaData.to = response.data.meta.to
      gamesMetaData.total = response.data.meta.total
      gamesMetaData.lastPage = response.data.meta.last_page
      overlay.value = false

      // console.log(items.value)
    })
    .catch((error) => {
      console.log(error)
    })
}

function getFormattedTime(time) {
  const date = new Date(time)
  // Format the time to  12-hour clock with AM/PM
  const formattedTime = date.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  })

  // console.log(formattedTime);
  return formattedTime
}

function getFormattedAmount(amount) {
  return new Intl.NumberFormat("en-US").format(amount)
}

function filterByDate() {
  overlay.value = true
  getAllGamesInfo()
}

onMounted(() => {
  getAllGamesInfo()
})
</script>
<style scoped>
* {
  /* outline: 1px solid green; */
  font-family: "Nunito", sans-serif;
}

.queryBtn {
  padding: 0.5rem 1.2rem;
  /* border:1px solid #555555; */
  border: 1px solid rgba(255, 255, 255, 0.08);
  margin: 0.8rem;
  border-radius: 4px;
  font-size: 0.95rem;
  color: rgba(255, 255, 255, 0.25);
  box-shadow: 2px 2px 2px solid #555;
}
.queryBtn.active {
  /* Active state styles */
  border: 1px solid rgba(255, 255, 255, 0.68);
  color: rgba(255, 255, 255, 0.68);
}

.queryBtn:hover {
  border: 1px solid rgba(255, 255, 255, 0.68);
  color: rgba(255, 255, 255, 0.68);
}

.fs-3 {
  font-size: 0.95rem;
}
.fs-4 {
  font-size: 1rem;
}
.fs-5 {
  font-size: 2rem;
}

.row-scroller {
  position: relative;
}

.row-scroller .row {
  display: flex;
  /* flex-wrap: wrap; */
  overflow-x: auto;
  scroll-behavior: smooth;
}
</style>
