<template>
  <AdminLayout page="KYC Approvals">
    <v-container>
      <v-row>
        <v-col cols="12">
          <div class="d-flex justify-space-between align-center">
            <p class="mt-0 text-darker-2">
              Quick Summary of all the information on this platform
            </p>
            
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="6" lg="3">
          <div class="bg-dark-3 rounded pa-4">
            <v-img
              src="../../assets/kycApprovals1-icon.svg"
              width="40"
              class=""
            ></v-img>
            <h3 class="mt-4 font-weight-light">All Users</h3>
            <h2 class="mt-3 fs-6">2,000</h2>
          </div>
        </v-col>
        <v-col cols="12" md="6" lg="3">
          <div class="bg-dark-3 rounded pa-4">
            <v-img
              src="../../assets/kycApproval2-icon.svg"
              width="40"
              class=""
            ></v-img>
            <h3 class="mt-4 font-weight-light">Approved Requests</h3>
            <h2 class="mt-3 fs-6">400</h2>
          </div>
        </v-col>
        <v-col cols="12" md="6" lg="3">
          <div class="bg-dark-3 rounded pa-4">
            <v-img
              src="../../assets/kycApproval3-icon.svg"
              width="40"
              class=""
            ></v-img>
            <h3 class="mt-4 font-weight-light">Pending Request</h3>
            <h2 class="mt-3 fs-6">21,219</h2>
          </div>
        </v-col>
        <v-col cols="12" md="6" lg="3">
          <div class="bg-dark-3 rounded pa-4">
            <v-img
              src="../../assets/kycApproval4-icon.svg"
              width="40"
              class=""
            ></v-img>
            <h3 class="mt-4 font-weight-light">Rejected Request</h3>
            <h2 class="mt-3 fs-6">7,121</h2>
          </div>
        </v-col>
      </v-row>

     

      <div class="row-scroller mt-5 ">
        <v-row class="row rounded">
          <v-col cols="12" lg="9" class="">
            <div class="d-flex bg-dark-5 rounded px-5 py-2">
              
                <v-text-field
                  rounded
                  variant="outlined"
                  density="compact"
                  placeholder="Search For Approval"
                  class="mt-5 w-100"
                  v-model="search"
                ></v-text-field>
             

              <div class="d-flex align-center">
                <v-btn variant="outlined" color="dark-2" class="mx-3"
                  >All Request</v-btn
                >

                <v-btn variant="outlined" color="dark-2" class="mx-3 p-3" 
                  >Approved</v-btn
                >

                <v-btn variant="outlined" color="dark-2" class="px-3 mx-3" 
                  >Rejected</v-btn
                >
                <v-btn variant="outlined" color="dark-2" class="px-3 mx-3" 
                  >Pending</v-btn
                >
              </div>
            </div>
          </v-col>
          <v-col cols="12" lg="3" class=" d-flex align-center " >

            <div class="bg-dark-5 px-4 py-8 rounded elevation-3">

              <v-btn
              variant="outlined"
              color="dark-2"
              class="ms-3"
              
              prepend-icon="mdi-calendar"
              >Select Period</v-btn
            >
            </div>
          </v-col>
        </v-row>
      </div>

      <div class="mt-7">
        <v-data-table :headers="headers" :items="items" class="elevation-1">
          <template v-slot:top> </template>
          <template #bottom></template>
        </v-data-table>
        <v-pagination :length="4"></v-pagination>
      </div>
    </v-container>
  </AdminLayout>
</template>
<script setup>
import AdminLayout from "../../components/AdminLayout.vue";
import { ref } from "vue";

const headers = ref([
  {
    title: "Player ID",
    align: "start",

    key: "name",
  },

  { title: "Player Name", align: "start", key: "email" },
  { title: "Type", align: "start", key: "phone_no" },
  { title: "Status", align: "start", key: "state" },
  { title: "Transaction Type", align: "start", key: "state" },
  { title: "Amount", align: "start", key: "state" },
  { title: "Request Date", key: "state" },
]);
</script>
<style scoped>
* {
  /* outline: 1px solid green; */
  font-family: "Nunito", sans-serif;
}
</style>