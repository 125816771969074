<template>
  <AdminLayout page="Transactions">
    <v-snackbar
      v-model="snackbar.status"
      :color="snackbar.color"
      :timeout="snackbar.timeout"
      location="top"
      >{{ snackbar.msg }}</v-snackbar
    >
    <div v-if="overlay">
      <DataTable />
    </div>
    <v-container v-else>
      <v-row>
        <v-col cols="12">
          <div class="d-flex justify-space-between align-center">
            <p class="mt-0 text-dark-2">
              Quick Summary of all the information on this platform
            </p>
            <v-btn class="elevation-0" color="primary" size="large"
              >Fund Token Wallet
              <v-dialog
                v-model="dialog"
                activator="parent"
                width="600"
                opacity="-0.9"
                transition="dialog-top-transition"
              >
                <v-card class="pa-6 bg-dark elevation-3 shadow-lg">
                  <div class="d-flex align-center justify-space-between">
                    <div>
                      <h3 class="fs-5">Fund Token Wallet</h3>
                      <p>Fund User's Wallet</p>
                    </div>
                    <div>
                      <!-- <v-img src="../../assets/close-btn.png" width="40" height="40"></v-img> -->
                      <v-btn icon="mdi-close" @click="dialog = false"></v-btn>
                    </div>
                  </div>
                  <v-form
                    class="mt-5"
                    @submit.prevent="fundWallet"
                    ref="formRef"
                  >
                    <v-row>
                      <v-col cols="12">
                        <p class="mb-3">Select User</p>
                        <v-autocomplete
                          variant="solo"
                          :items="userData"
                          item-title="username"
                          v-model="addToFundWallet.user_id"
                          item-value="id"
                          placeholder="Enter Full Name to search for user"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12">
                        <p class="mb-3">Token Amount</p>
                        <v-text-field
                          variant="solo"
                          type="number"
                          v-model="addToFundWallet.amount"
                          placeholder="Enter number of tokens"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-btn
                          type="submit"
                          color="primary"
                          block
                          size="large"
                          :disabled="loading"
                          :loading="loading"
                          >Fund Token Wallet</v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card>
              </v-dialog>
            </v-btn>
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="6" lg="3">
          <div class="bg-dark-3 rounded pa-4">
            <v-img
              src="../../assets/transaction-icon1.png"
              width="40"
              class=""
            ></v-img>
            <h3 class="mt-4 font-weight-light">Total Transactions</h3>
            <h2 class="mt-3 fs-6">
              {{
                items2.total_transaction
                  ? getFormattedAmount(items2.total_transaction)
                  : "0"
              }}
            </h2>
          </div>
        </v-col>
        <v-col cols="12" md="6" lg="3">
          <div class="bg-dark-3 rounded pa-4">
            <v-img
              src="../../assets/transaction-icon2.png"
              width="40"
              class=""
            ></v-img>
            <h3 class="mt-4 font-weight-light">Total No of Token</h3>
            <h2 class="mt-3 fs-6">
              {{
                items2.total_token
                  ? getFormattedAmount(items2.total_token)
                  : "0"
              }}
            </h2>
          </div>
        </v-col>
        <v-col cols="12" md="6" lg="3">
          <div class="bg-dark-3 rounded pa-4">
            <v-img
              src="../../assets/transaction-icon3.png"
              width="40"
              class=""
            ></v-img>
            <h3 class="mt-4 font-weight-light">Total No of Coins</h3>
            <h2 class="mt-3 fs-6">
              {{
                items2.total_coin ? getFormattedAmount(items2.total_coin) : "0"
              }}
            </h2>
          </div>
        </v-col>
        <v-col cols="12" md="6" lg="3">
          <div class="bg-dark-3 rounded pa-4">
            <v-img
              src="../../assets/transaction-icon4.png"
              width="40"
              class=""
            ></v-img>
            <h3 class="mt-4 font-weight-light">Total Revenue Generated</h3>
            <h2 class="mt-3 fs-6">
              {{
                items2.total_revenue
                  ? getFormattedAmount(items2.total_revenue)
                  : "0"
              }}
            </h2>
          </div>
        </v-col>
      </v-row>

      <div class="row-scroller mt-5">
        <v-row class="row rounded">
          <v-col cols="12" lg="9" class="">
            <div class="d-flex bg-dark-5 rounded px-3 align-center">
              <input
                type="search"
                placeholder="Search for Transaction"
                class="querySearch"
                v-model="search"
              />

              <div class="d-flex align-center">
                <button class="queryBtn" @click.prevent="filterByDate">
                  All Transaction
                </button>
              </div>
            </div>
          </v-col>
          <v-col cols="12" lg="3" class="d-flex align-center">
            <div class="bg-dark-5 px-2 rounded">
              <button class="queryBtn d-flex align-center" @click="showDate">
                <v-img
                  src="../../assets/calendar.svg"
                  width="30"
                  height="20"
                  class="me-1"
                ></v-img
                >Select Period
              </button>
            </div>
          </v-col>
        </v-row>
      </div>

      <div v-if="monthFields">
        <v-row class="mt-3">
          <v-col cols="6">
            <p class="mb-1">Start Month</p>
            <v-text-field
              variant="outlined"
              v-model="startMonth"
              type="date"
              class="me-3 rounded"
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <p class="mb-1">End Month</p>

            <v-text-field
              variant="outlined"
              v-model="endMonth"
              type="date"
              @input="filterByDate"
              class="rounded"
            ></v-text-field>
          </v-col>
        </v-row>
      </div>

      <div class="mt-7">
        <v-data-table
          :headers="headers"
          :items="items"
          class="elevation-2 bg-dark-5 text-dark-6 font-weight-bold pa-3 shadow-lg"
          :search="search"
        >
          <template v-slot:top> </template>

          <template v-slot:[`item.created_at`]="{ item }">
            {{ getFormattedTime(item.created_at) }}
          </template>
          <template v-slot:[`item.amount`]="{ item }">
            ₦{{ getFormattedAmount(item.amount) }}
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <v-chip
              class="text-capitalize"
              :color="item.status == 'successful' ? 'green' : 'orange'"
              >{{ item.status }}</v-chip
            >
          </template>
          <template v-slot:bottom>
            <div class="text-center pt-2">
              <v-pagination
                v-model="transactionMetaData.currentPage"
                :length="transactionMetaData.lastPage"
                @click.prevent="
                  initTransactionData(transactionMetaData.currentPage)
                "
              ></v-pagination>
            </div>
          </template>
        </v-data-table>

        <p class="text-end mt-3">
          <span class="mt-3"
            >Showing {{ transactionMetaData.from }} -
            {{ transactionMetaData.to }} of {{ transactionMetaData.total }}
          </span>
        </p>
      </div>
    </v-container>
  </AdminLayout>
</template>

<script setup>
import AdminLayout from "../../components/AdminLayout.vue"
import axiosInstance from "../../plugins/axios"

import DataTable from "../../components/DataTable.vue"

import { ref, reactive, onMounted } from "vue"

const items = ref([])
const items2 = ref([])
const userData = ref([])

const loading = ref(false)
const monthFields = ref(false)

const startMonth = ref("")
const endMonth = ref("")
const dialog = ref(false)
const overlay = ref(true)

const snackbar = reactive({
  status: false,
  msg: "",
  color: "",
  timeout: 5000,
})

const transactionMetaData = reactive({
  currentPage: "",
  from: "",
  to: "",
  perPage: "",
  total: "",
  lastPage: "",
})

const formRef = ref(null)

const headers = ref([
  {
    title: "Transaction ID",
    align: "start",
    searchable: true,
    key: "transaction_ref",
  },

  { title: "Player Name", align: "start", searchable: true, key: "sender" },
  { title: "Type", align: "start", searchable: true, key: "transaction_type" },
  { title: "Amount", align: "start", searchable: true, key: "amount" },
  {
    title: "Category",
    align: "start",
    searchable: true,
    key: "transaction_type",
  },
  { title: "Status", align: "start", searchable: true, key: "status" },
  { title: "Time", key: "created_at" },
])

const search = ref("")

const addToFundWallet = reactive({
  user_id: "",
  amount: "",
})

function showDate() {
  monthFields.value = true
}

function filterByDate() {
  overlay.value = true
  initTransactionData()
}

function fundWallet() {
  axiosInstance
    .post(`/admin/tokens`, addToFundWallet)
    .then((response) => {
      console.log(response.data.message, 3243)
      loading.value = false
      snackbar.status = true
      snackbar.color = "green"
      snackbar.msg = response.data.message
      formRef.value.reset()
      dialog.value = false
    })
    .catch((error) => {
      console.log(error)
      loading.value = false

      snackbar.status = true
      snackbar.color = "red"
      snackbar.msg = error.response.data.message
      dialog.value = false
    })
}

function getFormattedTime(time) {
  const date = new Date(time) // Parse the date string into a Date object

  // Extract the day, month, year, hours, and minutes
  const day = String(date.getDate()).padStart(2, "0")
  const month = String(date.getMonth() + 1).padStart(2, "0") // Months are  0-indexed
  const year = date.getFullYear()
  const hours = ((date.getHours() + 11) % 12) + 1 // Convert to  12-hour format
  const minutes = date.getMinutes().toString().padStart(2, "0")
  const period = date.getHours() >= 12 ? "PM" : "AM" // Determine AM/PM

  // Construct the formatted date string
  const formattedDate = `${day}/${month}/${year} ${hours}:${minutes}${period}`

  return formattedDate
}

// function getColor(item) {
//   if (item == "successful") return "green";
//   else return "orange";
// }

function getFormattedAmount(amount) {
  return new Intl.NumberFormat("en-US").format(amount)
}

async function initTransactionData(page = 1) {
  overlay.value = true

  try {
    const transactionData = await axiosInstance.get(
      `/admin/transactions?page=${page}`
    )
    const transactionOverviewData = await axiosInstance.get(
      `/admin/transaction/overview`
    )
    const usersData = await axiosInstance.get(`/admin/users`)
    items.value = transactionData.data.data
    items2.value = transactionOverviewData.data
    userData.value = usersData.data.data
    // console.log(userData.value);

    transactionMetaData.currentPage = transactionData.data.meta.current_page
    console.log(transactionMetaData.currentPage)
    transactionMetaData.perPage = transactionData.data.meta.per_page
    transactionMetaData.from = transactionData.data.meta.from
    transactionMetaData.to = transactionData.data.meta.to
    transactionMetaData.total = transactionData.data.meta.total
    transactionMetaData.lastPage = transactionData.data.meta.last_page

    overlay.value = false
  } catch (e) {
    console.log(e)
    overlay.value = false
  }
}

onMounted(() => {
  initTransactionData()
})
</script>
<style scoped>
* {
  /* outline: 1px solid green; */
  font-family: "Nunito", sans-serif;
}

.queryBtn {
  padding: 0.5rem 1.2rem;
  /* border:1px solid #555555; */
  border: 1px solid rgba(255, 255, 255, 0.08);
  margin: 0.8rem;
  border-radius: 4px;
  font-size: 0.95rem;
  color: rgba(255, 255, 255, 0.25);
  box-shadow: 2px 2px 2px solid #555;
}

.queryBtn:hover {
  border: 1px solid rgba(255, 255, 255, 0.68);
  color: rgba(255, 255, 255, 0.68);
}

.querySearch {
  border: 1px solid rgba(255, 255, 255, 0.08);
  font-size: 0.95rem;
  box-shadow: 2px 2px 2px solid #555;
  padding: 0.5rem 1.2rem;
  border-radius: 5rem;

  width: 80%;
}

.querySearch:focus {
  outline: none;
  border: 1px solid rgba(255, 255, 255, 0.68);
  color: rgba(255, 255, 255, 0.68);

  /* border:none; */
}

.fs-3 {
  font-size: 1.5rem;
}

.w-90 {
  width: 97%;
}

.row-scroller {
  position: relative;
}

.row-scroller .row {
  display: flex;
  /* flex-wrap: wrap; */
  overflow-x: auto;
  scroll-behavior: smooth;
}
</style>
