<template>
  <AdminLayout page="Users">
     <v-snackbar
      v-model="snackbar.status"
      :color="snackbar.color"
      :timeout="snackbar.timeout"
      location="top"
      >{{ snackbar.msg }}</v-snackbar
    >
    <div v-if="overlay">
      <DataTable />
    </div>
    <v-container v-else>
      <v-row>
        <v-col cols="12">
          <div class="d-flex justify-space-between align-center">
            <p class="mt-0 text-dark-2">
              Quick Summary of all the information on this platform
            </p>
            <div>
              <v-btn class="elevation-0" color="gray" size="large">
                Deactivate User
                <v-dialog
                  v-model="dialog"
                  activator="parent"
                  width="600"
                  opacity="-0.5"
                  transition="dialog-top-transition"
                >
                  <v-card class="pa-5 bg-dark elevation-3">
                    <div class="d-flex align-center justify-space-between">
                      <div>
                        <h3 class="fs-5">Deactivate User</h3>
                        <p>Deactivate User Status</p>
                      </div>
                      <div>
                        <!-- <v-img src="../../assets/close-btn.png" width="40" height="40"></v-img> -->
                        <v-btn
                          icon="mdi-close"
                          @click="dialog = false"
                        ></v-btn>
                      </div>
                    </div>
                    <v-form class="mt-5" @submit.prevent="userStatus">
                      <v-row>
                        <v-col cols="12">
                          <p class="mb-3">Select User</p>
                          <v-autocomplete
                          variant="solo"
                          :items="userData"
                          item-title="username"
                          v-model="addUserStatus.user_id"
                          item-value="id"
                          placeholder="Enter Full Name to search for user"
                        ></v-autocomplete>
                        </v-col>
                        <v-col cols="12">
                          <p class="mb-3">Status</p>
                        <v-switch
                          v-model="addUserStatus.status"
                          hide-details
                         
                          
                        ></v-switch>
                      </v-col>
                        <v-col cols="12">
                          <v-btn
                            type="submit"
                            color="primary"
                            block
                            size="large"
                            >Deactivate</v-btn
                          >
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card>
                </v-dialog>
              </v-btn>
              <v-btn class="elevation-0 ms-3" color="primary" size="large">
                Activate User
                <v-dialog
                  v-model="dialog2"
                  activator="parent"
                  width="600"
                  opacity="-0.5"
                  transition="dialog-top-transition"
                >
                  <v-card class="pa-5 bg-dark elevation-3">
                    <div class="d-flex align-center justify-space-between">
                      <div>
                        <h3 class="fs-5">Activate User</h3>
                        <p>Activate User Status</p>
                      </div>
                      <div>
                        <!-- <v-img src="../../assets/close-btn.png" width="40" height="40"></v-img> -->
                        <v-btn icon="mdi-close" @click="dialog2 = false"></v-btn>
                      </div>
                    </div>
                    <v-form class="mt-5" @submit.prevent="userStatus">
                      <v-row>
                        <v-col cols="12">
                          <p class="mb-3">Select User</p>
                          <v-autocomplete
                          variant="solo"
                          :items="userData"
                          item-title="username"
                          v-model="addUserStatus.user_id"
                          item-value="id"
                          placeholder="Enter Full Name to search for user"
                        ></v-autocomplete>
                        </v-col>
                         <v-col cols="12">
                          <p class="mb-3">Status</p>
                        <v-switch
                          v-model="addUserStatus.status"
                          hide-details
                         
                          
                        ></v-switch>
                      </v-col>
                        <v-col cols="12">
                          <v-btn
                            type="submit"
                            color="primary"
                            block
                            size="large"
                            >Activate</v-btn
                          >
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card>
                </v-dialog>
              </v-btn>
            </div>
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="6" lg="3">
          <div class="bg-dark-3 rounded pa-4">
            <v-img
              src="../../assets/user-icon1.png"
              width="40"
              class=""
            ></v-img>
            <h3 class="mt-4 font-weight-light">Total No of Users</h3>
            <h2 class="mt-3 fs-6">
              {{ item2.all_users ? getFormattedAmount(item2.all_users) : "0" }}
            </h2>
          </div>
        </v-col>
        <v-col cols="12" md="6" lg="3">
          <div class="bg-dark-3 rounded pa-4">
            <v-img
              src="../../assets/user-icon2.png"
              width="40"
              class=""
            ></v-img>
            <h3 class="mt-4 font-weight-light">Total No of Active Users</h3>
            <h2 class="mt-3 fs-6">
              {{
                item2.active_users
                  ? getFormattedAmount(item2.active_users)
                  : "0"
              }}
            </h2>
          </div>
        </v-col>
        <v-col cols="12" md="6" lg="3">
          <div class="bg-dark-3 rounded pa-4">
            <v-img
              src="../../assets/user-icon3.png"
              width="40"
              class=""
            ></v-img>
            <h3 class="mt-4 font-weight-light">Total No of Inactive Users</h3>
            <h2 class="mt-3 fs-6">
              {{
                item2.inactive_users
                  ? getFormattedAmount(item2.inactive_users)
                  : "0"
              }}
            </h2>
          </div>
        </v-col>
        <v-col cols="12" md="6" lg="3">
          <div class="bg-dark-3 rounded pa-4">
            <v-img
              src="../../assets/user-icon4.png"
              width="40"
              class=""
            ></v-img>
            <h3 class="mt-4 font-weight-light">Total No of Disabled Users</h3>
            <h2 class="mt-3 fs-6">0</h2>
          </div>
        </v-col>
      </v-row>

      <div class="row-scroller mt-5">
        <v-row class="row rounded">
          <v-col cols="12" lg="12" class="">
            <div class="d-flex bg-dark-5 rounded px-3 align-center pa-2">
              <input
                type="search"
                placeholder="Search for User"
                class="querySearch"
                v-model="search"
              />

              <div class="d-flex align-center">
                <button class="queryBtn">All Users</button>

                <button class="queryBtn">Active Users</button>

                <button class="queryBtn">Inactive Users</button>
                <button class="queryBtn d-flex align-center flex-nowrap" @click="showDate">
                  <v-img
                    src="../../assets/calendar.svg"
                    width="30"
                    height="20"
                    class="me-1"
                  ></v-img
                  >Select Period
                </button>
              </div>
            </div>
          </v-col>
          
        </v-row>
      </div>

      <div v-if="monthFields">
            <v-row class="mt-3">
              <v-col cols="6">
                <p class="mb-1">Start Month</p>
                <v-text-field
                  variant="outlined"
                  v-model="startMonth"
                  type="date"
                  class="me-3 rounded"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <p class="mb-1">End Month</p>

                <v-text-field
                  variant="outlined"
                  v-model="endMonth"
                  type="date"
                  @input="filterByDate"
                  class="rounded"
                ></v-text-field>
              </v-col>
            </v-row>
          </div>

      <div class="mt-7">
        <v-data-table
          :headers="headers"
          :items="items"
          class="elevation-2 bg-dark-5 text-dark-6 font-weight-bold pa-3 shadow-lg"
          :search="search"
        >
          <template v-slot:top> </template>
          <template v-slot:[`item.is_active`]="{ item }">
            <v-chip
              class="text-capitalize"
              :color="item.is_active == true ? 'green' : 'red'"
              >{{ item.is_active ? "Active" : "Disabled" }}</v-chip
            >
          </template>
          <template v-slot:[`item.username`]="{ item }">
            <span>@{{ item.username }}</span>
          </template>
          <template v-slot:[`item.token`]="{ item }">
            <span>{{ getFormattedAmount(item.token) }}</span>
          </template>
          <template v-slot:[`item.coin`]="{ item }">
            <span>{{ getFormattedAmount(item.coin) }}</span>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <router-link
              class="text-decoration-none text-primary text-center"
              :to="{
                name: 'AdminViewSingleUser',
                params: { user_id: item.id },
              }"
            >
              <v-icon small class="me-2"> mdi-eye </v-icon>View
            </router-link>
          </template>
           <template v-slot:bottom>
      <div class="text-center pt-2">
        <v-pagination
          v-model="userMetaData.currentPage" :length="userMetaData.lastPage" @click.prevent="initUserData(userMetaData.currentPage)"
        ></v-pagination>
      </div>
    </template>
        </v-data-table>
      </div>
    </v-container>
  </AdminLayout>
</template>

<script setup>
import AdminLayout from "../../components/AdminLayout.vue"

import axiosInstance from "../../plugins/axios"
import DataTable from "../../components/DataTable.vue"

import { ref, onMounted , reactive } from "vue"

const items = ref([])
const item2 = ref([])

const dialog = ref(false)
const dialog2 = ref(false)
const overlay = ref(true)

const addUserStatus = reactive({
  user_id: "",
  status: "",
})


function userStatus(){
  axiosInstance
  .patch(`/admin/users/${addUserStatus.user_id}`, addUserStatus)
  .then(response => {
    console.log(response)
    snackbar.status = true
      snackbar.color = "green"
      snackbar.msg = response.data.message
      dialog.value = false
      dialog2.value = false

  })
  .catch(error => {
    console.log(error)
    snackbar.status = true
      snackbar.msg = error.response.data.message
      snackbar.color = "red"
       dialog.value = false
      dialog2.value = false
  })
}

const snackbar = reactive({
  status: false,
  msg: "",
  color: "",
  timeout: 5000,
})

const startMonth = ref("")
const endMonth = ref("")
const monthFields = ref(false)


const headers = ref([
  {
    title: "Player ID",
    align: "start",
    searchable: true,
    key: "username",
  },

  { title: "Player Name", align: "start", searchable: true, key: "fullname" },
  { title: "Status", align: "start", searchable: true, key: "is_active" },
  { title: "Token Balance", align: "start", searchable: true, key: "token" },
  { title: "Coin Balance", align: "start", searchable: true, key: "coin" },
  { title: "Games Played", align: "start", searchable: true, key: "state" },
  { title: "Last Online", searchable: true, key: "state" },
  { title: "Actions", searchable: true, value: "actions", sortable: false },
])

const search = ref("")

function showDate() {
  monthFields.value = true
}

function filterByDate() {
  overlay.value = true
  getUserData()
}

function getUserData() {
  axiosInstance
    .get(`/admin/users`)
    .then((response) => {
      items.value = response.data.data
      overlay.value = false

      console.log(items.value)
    })
    .catch((error) => {
      console.log(error)
    })
}
function getUserOverviewData() {
  axiosInstance
    .get(`/admin/users/overview`)
    .then((response) => {
      item2.value = response.data
      overlay.value = false
      // console.log(item2.value, "55555");
    })
    .catch((error) => {
      console.log(error)
    })
}

function getFormattedAmount(amount) {
  return new Intl.NumberFormat("en-US").format(amount)
}

const userData = ref([])

const userMetaData = reactive({
  currentPage: "",
  from: "",
  to: "",
  perPage: "",
  total: "",
  lastPage: "",
});


async function initUserData(page = 1) {
    overlay.value = true

  try {
    
    const usersData = await axiosInstance.get(`/admin/users?page=${page}`)
    
   
    userData.value = usersData.data.data
    // console.log(userData.value);
     userMetaData.currentPage = usersData.data.meta.current_page;
    console.log(userMetaData.currentPage);
    userMetaData.perPage = usersData.data.meta.per_page;
    userMetaData.from = usersData.data.meta.from;
    userMetaData.to = usersData.data.meta.to;
    userMetaData.total = usersData.data.meta.total;
    userMetaData.lastPage = usersData.data.meta.last_page;


    overlay.value = false
  } catch (e) {
    console.log(e)
    overlay.value = false
  }
}

onMounted(() => {
  initUserData()
  getUserData()
  getUserOverviewData()
})
</script>
<style scoped>
* {
   /* outline: 1px solid green;  */
  font-family: "Nunito", sans-serif;
}

.queryBtn {
  padding: 0.3rem 1rem;
  /* border:1px solid #555555; */
  border: 1px solid rgba(255, 255, 255, 0.08);
  margin: 0.8rem;
  border-radius: 4px;
  font-size: 0.95rem;
  color: rgba(255, 255, 255, 0.25);
  box-shadow: 2px 2px 2px solid #555;
}

.queryBtn:hover {
  border: 1px solid rgba(255, 255, 255, 0.68);
  color: rgba(255, 255, 255, 0.68);
}

.querySearch {
  border: 1px solid rgba(255, 255, 255, 0.08);
  font-size: 0.95rem;
  box-shadow: 2px 2px 2px solid #555;
  padding: 0.5rem 1.2rem;
  border-radius: 5rem;

  width: 45%;
}

.querySearch:focus {
  outline: none;
  border: 1px solid rgba(255, 255, 255, 0.68);
  color: rgba(255, 255, 255, 0.68);

  /* border:none; */
}

.fs-3 {
  font-size: 1.5rem;
}
.fs-5 {
  font-size: 2rem;
}

.row-scroller {
  position: relative;
}

.row-scroller .row {
  display: flex;
  /* flex-wrap: wrap; */
  overflow-x: auto;
  scroll-behavior: smooth;
}
</style>
