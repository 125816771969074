<template>
  <AdminLayout page="Profile and Settings">
    <v-snackbar
      v-model="snackbar.status"
      :color="snackbar.color"
      :timeout="snackbar.timeout"
      location="top"
      >{{ snackbar.msg }}</v-snackbar
    >
    <v-container v-if="overlay">

      <v-skeleton-loader type="card" ></v-skeleton-loader>
    </v-container>
    <v-container v-else>
      <p class="mt-0 text-dark-2">Customise and personalise your settings</p>

      <v-card class="mt-8 bg-dark-5">
        <v-row>
          <v-col cols="12" lg="3">
            <v-tabs v-model="tab" color="primary" direction="vertical">
              <v-tab value="one"
                ><v-btn variant="outlined" block
                  >Personal Information</v-btn
                ></v-tab
              >
              <v-tab value="two"
                ><v-btn variant="outlined" block
                  >Change Password<span style="visibility: hidden"
                    >pel</span
                  ></v-btn
                ></v-tab
              >
            </v-tabs>
          </v-col>

          <v-col cols="12" lg="9">
            <v-card-text>
              <v-window v-model="tab">
                <v-window-item value="one">
                  <v-form class="mt-7" @submit.prevent="editUserDetails">
                    <v-row>
                      <v-col cols="12">
                        <div
                          class="d-block d-lg-flex ms-0 ms-lg-5 justify-space-between"
                        >
                          <div class="d-flex">
                            <v-img
                              src="../../assets/bolajiAmusan.svg"
                              width="200"
                              height="200"
                            ></v-img>
                            <!-- <v-btn
                              class="rounded-pill"
                              size="x-small"
                              variant="outlined"
                              >Change Image</v-btn
                            > -->
                          </div>

                          <div class="mt-3 mt-lg-0">
                            <v-btn
                              color="primary"
                              type="submit"
                              :disabled="loading"
                              :loading="loading"
                              >Save Changes</v-btn
                            >
                          </div>
                        </div>
                      </v-col>
                      <v-col cols="12" lg="6">
                        <p class="fs-6 mb-2">Name</p>
                        <v-text-field
                          variant="outlined"
                          v-model="userDetails.fullname"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" lg="6">
                        <p class="fs-6 mb-2">Email</p>
                        <v-text-field
                          variant="outlined"
                          type="email"
                          v-model="userDetails.email"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" lg="6">
                        <p class="fs-6 mb-2">Phone Number</p>
                        <v-text-field
                          variant="outlined"
                          type="number"
                          v-model="userDetails.phone"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-window-item>

                <v-window-item value="two">
                  <v-form class="mt-7">
                    <v-row>
                      <v-col cols="12" lg="6">
                        <p class="fs-6 mb-2">Current Password</p>
                        <v-text-field
                          variant="outlined"
                          placeholder="Enter current Password"
                          v-model="userPasswordReset.current_password"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" lg="6">
                        <p class="fs-6 mb-2">New Password</p>
                        <v-text-field
                          variant="outlined"
                          type="email"
                          placeholder="Enter new Password"
                          v-model="userPasswordReset.new_password"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" lg="6">
                        <p class="fs-6 mb-2">Confirm Password</p>
                        <v-text-field
                          variant="outlined"
                          type="number"
                          placeholder="Enter Phone Number"
                          v-model="userPasswordReset.new_password_confirmation"
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <p class="text-end mt-5">
                      <v-btn
                        color="primary"
                        size="large"
                        class="elevation-3"
                        @click="editPassword"
                        >Save Changes</v-btn
                      >
                    </p>
                  </v-form>
                </v-window-item>
              </v-window>
            </v-card-text>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </AdminLayout>
</template>
<script setup>
import { ref, reactive, onMounted } from "vue"
import AdminLayout from "../../components/AdminLayout.vue"
import axiosInstance from "../../plugins/axios"

const loading = ref(false)
const overlay = ref(true)

const snackbar = reactive({
  status: false,
  msg: "",
  color: "",
  timeout: 5000,
})

const userDetails = ref([])

const userPasswordReset = reactive({
  current_password: "",
  new_password: "",
  new_password_confirmation: "",
})

function getUserProfile() {
  axiosInstance
    .get(`/admin/profile`)
    .then((response) => {
      userDetails.value = response.data.data
      overlay.value = false
      // console.log(userDetails.value)
    })
    .catch((error) => {
      console.log(error)
    })
}

function editUserDetails() {
  loading.value = true

  const user = {
    name: userDetails.value.fullname,
    phone: userDetails.value.phone,
  }

  axiosInstance
    .put(`/admin/profile`, user)
    .then((response) => {
      loading.value = false

      snackbar.status = true
      snackbar.color = "green"
      snackbar.msg = response.data.message
    })
    .catch((error) => {
      loading.value = false

      console.log(error)
      snackbar.status = true
      snackbar.msg = error.response.data.message
      snackbar.color = "red"
    })
}

function editPassword() {
  axiosInstance
    .put(`/admin/profile/change-password`, userPasswordReset)
    .then((response) => {
      snackbar.status = true
      snackbar.color = "green"
      snackbar.msg = response.data.message
    })
    .catch((error) => {
      snackbar.status = true
      snackbar.msg = error.response.data.message
      snackbar.color = "red"
    })
}

const tab = ref(null)

onMounted(() => {
  getUserProfile()
})
</script>
<style scoped>
* {
  /* outline: 1px solid green; */
  font-family: "Nunito", sans-serif;
}

.fs-6 {
  font-size: 1.5rem;
}
</style>
